
body {
    font-family: 'Roboto', sans-serif !important;

    --RedColor: #ec5e77;
    --TextColor: #333333;
    --LightTextColor: #666666;
    transition-duration: 0.3s;
    /* --PrimaryColor: #064185; */
    --PrimaryColor:  #e69221;
}


button {
    outline: none !important;
    transition-duration: 0.3s;
    font-family: 'Roboto', sans-serif !important;
}

p {
    font-size: 16px;
    line-height: 28px;
    color: var(--textcolor);
    font-family: 'Roboto', sans-serif !important;
}

a {
    outline: none !important;
    transition-duration: 0.3s;
    font-family: 'Roboto', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--headingColor);
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif !important;
}

.newhome-wrapper {
    min-height: 100vh;
    position: relative;
    padding: 82px 0px 0px 0px;
    overflow: hidden;
}

.newhome-wrapper .loginbtn {
    float: right;
    background: var(--RedColor);
    padding: 0px 20px;
    margin: 15px 0px 0px 0px;
    border-radius: 3px;
    outline: none;
    font-size: 14px;
    color: #fff;
    border: none;
    height: 28px;
    font-weight: 500;
}

.newhome-wrapper .skipbtn {
    float: right;
    font-size: 13px;
    color: #747474;
    background: none;
    outline: none;
    padding: 0;
    margin: 15px 10px 0px 0px;
    border: none;
    height: 28px;
    padding: 0px 20px;
}

.homescreen-header {
    width: 100%;
    position: absolute;
    top: 15px;
    left: 0px;
}

.homescreen-header .row {
    margin: 0px;
}

.homebutton {
    width: 100%;
    padding: 0;
    margin: 0;
    background: none !important;
    border: none;
    outline: none;
    min-height: 33.33%;
    cursor: pointer;
    text-align: left;
    position: relative;
}

.homebutton:nth-child(3) {
    background: #747474;
}

.homebutton .row {
    margin: 0px;
}

.homebutton .row .col-7,
.homebutton .row .col-5 {
    padding: 0px;
}

.homebutton p {
    font-size: 14px;
    padding: 0;
    margin: 0px 0px 10px 0px;
    color: #6f6f6f;
    line-height: 20px;
}

.homebutton p b {
    color: var(--RedColor);
}

.homebutton font {
    color: var(--RedColor);
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
}

.homebutton h5 {
    width: 100%;
    padding: 0;
    margin: 10px 0px 10px 0px;
    font-size: 18px;
    color: #000;
    font-weight: 500;
}

.homebutton .col-7,
.homebutton .col-5 {
    position: relative;
}

.homebutton span {
    position: relative;
    z-index: 5;
}

.home-slideone-banner {
    width: 310px;
    height: 310px;
    /* background: url(../img/banner-top-bg.jpg) no-repeat top left; */
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    right: -80px;
    top: -110px;
    z-index: -1;
}

.home-slidetwo-banner {
    width: 310px;
    height: 310px;
    /* background: url(../img/banner-middle-bg.jpg) no-repeat top right #ff00ff; */
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    left: -115px;
    top: -140px;
    z-index: -2;
}

.home-slidetwo-banner span {
    float: right;
}

.sectionone-anim::before {
    width: 190px;
    height: 190px;
    /* background: url(../img/circle.svg) no-repeat; */
    position: absolute;
    left: 60px;
    bottom: 45px;
    content: '';
    animation: cogwheelanim 6s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
}

@keyframes cogwheelanim {
    0% {
        transform: rotate(0deg);
    }



    100% {
        transform: rotate(360deg);
    }
}

.sectiontwo-anim::before {
    width: 190px;
    height: 190px;
    /* background: url(../img/circle.svg) no-repeat; */
    position: absolute;
    left: 60px;
    bottom: 45px;
    content: '';
    animation: cogwheelanim2 5s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
}

@keyframes cogwheelanim2 {
    0% {
        transform: rotate(0deg);
    }



    100% {
        transform: rotate(-360deg);
    }
}

.sectionthree-anim::before {
    width: 190px;
    height: 190px;
    /* background: url(../img/circle.svg) no-repeat; */
    position: absolute;
    left: 60px;
    bottom: 45px;
    content: '';
    animation: cogwheelanim3 8s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
}

@keyframes cogwheelanim3 {
    0% {
        transform: rotate(0deg);
    }



    100% {
        transform: rotate(360deg);
    }
}

.bodybg {
    min-height: 100vh;
    padding-top: 70px;

    background: rgba(236, 232, 230, 0.5);
    background: -moz-linear-gradient(top, rgba(236, 232, 230, 0.5) 0%, rgba(236, 232, 230, 0) 70%, rgba(236, 232, 230, 0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(236, 232, 230, 0.5)), color-stop(70%, rgba(236, 232, 230, 0)), color-stop(100%, rgba(236, 232, 230, 0)));
    background: -webkit-linear-gradient(top, rgba(236, 232, 230, 0.5) 0%, rgba(236, 232, 230, 0) 70%, rgba(236, 232, 230, 0) 100%);
    background: -o-linear-gradient(top, rgba(236, 232, 230, 0.5) 0%, rgba(236, 232, 230, 0) 70%, rgba(236, 232, 230, 0) 100%);
    background: -ms-linear-gradient(top, rgba(236, 232, 230, 0.5) 0%, rgba(236, 232, 230, 0) 70%, rgba(236, 232, 230, 0) 100%);
    background: linear-gradient(to bottom, rgba(236, 232, 230, 0.5) 0%, rgba(236, 232, 230, 0) 70%, rgba(236, 232, 230, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ece8e6', endColorstr='#ece8e6', GradientType=0);
}

.header-wrapper {
    width: 100%;
    height: 50px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1050;
    background: #fff;
}

.header-wrapper button {
    width: auto;
    height: 50px;
    background: none;
    outline: none;
    border: none;
    padding: 0px 0px;
    display: inline-block;
    font-size: 25px;
    position: relative;
    cursor: pointer;
    min-width: 50px;
    color: var(--TextColor);
}

.header-wrapper button:hover {
    color: var(--RedColor);
}

.header-wrapper img {
    height: 35px;
    width: auto;
    margin-left: 15px;
}

.header-wrapper .col-lg-6:last-child {
    text-align: right;
}

.createtag-btn {
    width: 100%;
    height: 200px;
    background: none;
    padding: 0;
    margin: 0;
    border: 1px dashed #cad2da;
    transition-duration: 0.3s;
}

.createtag-btn:hover {
    border-color: var(--RedColor);
}

.createtag-btn h6 {
    width: 100%;
    padding: 0;
    margin: 0;
    color: var(--TextColor);
    font-size: 15px;
}

.createtag-btn h4 {
    width: 100%;
    padding: 0;
    margin: 3px 0px 0px 0px;
    color: var(--TextColor);
    font-size: 18px;
    font-weight: 500;
}

.createtag-btn span {
    width: 80px;
    height: 66px;
    display: inline-block;
    /* background: url(../img/create-taglah.png) no-repeat; */
    margin-bottom: 8px;
}

.createtag-btn:hover span {
    background-position-y: -66px !important;
}

.create-meeting {
    background: none;
    /* background: url(../img/create-meeting.png) no-repeat !important; */
}

.create-survey-feedback {
    background: none;
    /* background: url(../img/create-survey-feedback.png) no-repeat !important; */
}

.mytag-wrapper {
    position: sticky;
    top: 0px;
}

.mytag-wrapper .tagheading {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    font-weight: bold;
    font-size: 15px;
}

.mytag-wrapper .tagheading button {
    float: right;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    font-size: 13px;
    color: var(--RedColor);
    text-decoration: underline;
    font-weight: 500;
}

.mytag-container {
    width: 100%;
    border: 1px solid #d9dfe5;
    margin-top: 12px;
    position: relative;
    min-height: 170px;
    background: #fff;
}

.mytag-container .taglah-body {
    height: 130px;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.mytag-container .taglah-body .col-xl-4 .propic-con {
    width: 100%;
    height: 100px;
    /* background: url(../img/profile-placeholder.png) no-repeat center #e6e7ed; */
    display: block;
    margin-top: 15px;
    border-radius: 4px;
    overflow: hidden;
}

.mytag-container .taglah-body .col-xl-4 .propic-con img {
    width: 100%;
    height: 100px;
    object-fit: contain;
}

.mytag-container .taglah-footer {
    border-top: 1px solid #d9dfe5;
}

.mytag-container .taglah-footer .row {
    margin-left: 0px;
    margin-right: 0px;
}

.mytag-container .taglah-footer .row .col {
    padding: 0px;
}

.mytag-container .taglah-footer button {
    height: 38px;
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    color: #9e9da3;
    background: none;
}

.mytag-container .taglah-footer button:hover {
    color: var(--RedColor);
}

.mytag-container .tag-counts {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 10px;
    z-index: 100;
}

.mytag-container .tag-counts .row {
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
    padding-right: 36px;
}

.mytag-container .tag-counts .row .col-4 {
    padding: 0px 0px;
}

.mytag-container .tag-counts .row .progressbar {
    position: absolute;
    top: -5px;
    right: 5px;
}


.center-tag-align {
    width: 36px;
    position: relative;
}

.mytag-container .tag-counts button {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    background: none;
    outline: none;
    border: none;
    color: #ced0d4;
    font-size: 16px;
    display: block;
    position: relative;
}

.mytag-container .tag-counts button .count {
    position: absolute;
    top: -5px;
    right: 0px;
    background: var(--RedColor);
    color: #fff;
    font-weight: 500;
    font-size: 8px;
    padding: 1px 3px;
    border-radius: 6px;
}

.mytag-container .taglah-body .col-xl-8 {
    display: table;
}

.mytag-container .taglah-body .col-xl-8 .tag-text-xon {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    padding-top: 24px;
}

.mytag-container .taglah-body .col-xl-8 .tag-text-xon h6 {
    width: 100%;
    padding: 0;
    margin: 3px 0px 0px 0px;
    font-size: 12px;
    color: #4b4b4b;
}

.mytag-container .taglah-body .col-xl-8 .tag-text-xon h5 {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    color: #000;
}

.taglah-body .col-xl-8 {
    padding-left: 0px;
}

.mytag-container .expiry-text {
    position: absolute;
    right: 14px;
    bottom: -6px;
    color: #9e9da3;
    font-size: 11px;
}

@keyframes growProgressBar {

    0%,
    33% {
        --pgPercentage: 0;
    }

    100% {
        --pgPercentage: var(--value);
    }
}

@property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
}

.progressbar {
    position: absolute;
    top: 0px;
    right: 24px;
}

.progressbar[role="progressbar"] {
    --size: 30px;
    --fg: #fe0710;
    --bg: #def;
    --pgPercentage: var(--value);
    animation: growProgressBar 3s 1 forwards;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: grid;
    place-items: center;
    background:
        radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
        conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    font-family: Helvetica, Arial, sans-serif;
    font-size: 10px;
    color: var(--TextColor) !important;
    font-weight: bold;
    color: var(--fg);
}

.progressbar[role="progressbar"]::before {
    counter-reset: percentage var(--value);
    content: counter(percentage) '%';
}



.default-tag:before {
    width: 39px;
    height: 39px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 100;
    /* background: url(../img/corner-star.png) no-repeat; */
    content: '';
}

.meetings-wrapper {
    margin-top: 20px;
}

.meetings-wrapper .tagheading {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    font-weight: bold;
    font-size: 15px;
}

.meetings-wrapper .tagheading button {
    float: right;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    font-size: 13px;
    color: var(--RedColor);
    text-decoration: underline;
    font-weight: 500;
}

.meetings-container {
    width: 100%;
    border: 1px solid #d9dfe5;
    margin-top: 12px;
    position: relative;
    min-height: auto;
    background: #fff;
    padding: 16px;
}

.meetings-container .meetings-item .col-xl-10 button {
    width: 100%;
    text-align: left;
    padding: 0;
    margin: 0;
    background: none;
    outline: none;
    border: none;

}

.meetings-container .meetings-item .col-xl-10 button h6 {
    font-size: 14px;
    color: var(--LightTextColor);
    padding: 0;
    margin: 0;
    line-height: 20px;
}

.meetings-container .meetings-item .col-xl-10 button h6 b {
    color: var(--TextColor);
}

.meetings-container .meetings-item .col-xl-10 button h6 .meetingname {
    color: var(--RedColor);
}

.meetings-container .meetings-item {
    border-bottom: 1px dashed #ababab;
    width: 100%;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.meetings-container .meetings-item:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.meetings-container .meetings-item .col-xl-2 button {
    float: right;
    padding: 0;
    margin: 0;
    background: none;
    outline: none;
    color: var(--TextColor);
    border: none;
}

.meetings-container .meetings-item .col-xl-2 button:hover {
    color: var(--RedColor);
}

.meetings-container .meetings-item .col-xl-10 button h6 .meeting-stat {
    display: inline-block;
    padding: 0px 10px;
    margin: 0;
    border-radius: 2px;
    font-size: 10px;
    background: #e6e6e6;
    line-height: 16px;
    margin-left: 5px;

}

.meetings-container .meetings-item .col-xl-10 button h6 .meeting-stat.approved {
    background: #d1e7dd;
    color: #40775e;
}

.meetings-container .meetings-item .col-xl-10 button h6 .meeting-stat.decline {
    background: #f8d7da;
    color: #a95556;
}

.meetings-container .meetings-item .col-xl-10 button h6 .meeting-stat.expired {
    background: #fff3cd;
    color: #8d786b;
}

.meetings-container .meetings-item .col-xl-10 button h6 .meeting-stat.commented {
    background: #cff4fc;
    color: #3e7f9f;
}

.taglah-post-wrap {
    width: 100%;
    border: 1px solid #d9dfe5;
    margin-top: 20px;
    position: relative;
    min-height: auto;
    background: #fff;
    padding: 20px;
}

.taglah-post-wrap .tagheading {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    font-weight: bold;
    font-size: 15px;
}

.taglah-post-wrap .tagheading button {
    float: right;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    font-size: 13px;
    color: var(--RedColor);
    text-decoration: underline;
    font-weight: 500;
}

.taglah-post-item {
    margin-top: 12px;
    width: 100%;
    position: relative;
}

.taglah-post-wrap .taglah-post-footer {
    border: 1px solid #d9dfe5;
    border-top: none;
}


.taglah-post-wrap .taglah-post-footer button {
    height: 38px;
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    color: #9e9da3;
    background: none;
    padding: 0px;
}

.taglah-post-wrap .taglah-post-footer button:hover {
    color: var(--RedColor);
}

.taglah-post-wrap .taglah-post-footer button .count {
    position: absolute;
    top: -5px;
    left: auto;
    right: 0px;
    background: var(--RedColor);
    color: #fff;
    font-weight: 500;
    font-size: 8px;
    padding: 1px 3px;
    border-radius: 6px;
    z-index: 500;
    width: auto;
}

.taglah-post-wrap .taglah-post-footer button div {
    width: 36px;
    position: relative;
}

.taglah-post-item .image-holder {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0px;
    background: none;
    border: none;
    outline: none;
    position: relative;
}

.taglah-post-item .image-holder:before {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    content: '';
    z-index: 90;
    height: 100px;

    background: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.85)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
}

.taglah-post-item .image-holder img {
    width: 100%;
    padding: 0;
    margin: 0;
    object-fit: cover;
}

.taglah-post-item .image-holder h6 {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 100;
    text-align: left;
    font-size: 14px;
    color: #fff;
    padding: 0px 15px 15px 15px;
    line-height: 20px;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);



}

.survey-feedback-wrapper {
    width: 100%;
    border: 1px solid #d9dfe5;
    margin-top: 20px;
    position: relative;
    min-height: auto;
    background: #fff;
    padding: 20px;
}

.survey-feedback-wrapper .tagheading {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    font-weight: bold;
    font-size: 15px;
}

.survey-feedback-wrapper .tagheading button {
    float: right;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    font-size: 13px;
    color: var(--RedColor);
    text-decoration: underline;
    font-weight: 500;
}


.survey-feedback-item .col-xl-3 button {
    height: 38px;
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    color: #9e9da3;
    background: none;
}

.survey-feedback-item .col-xl-3 button:hover {
    color: var(--RedColor);
}

.survey-feedback-item .col-xl-3 button .count {
    position: absolute;
    top: -5px;
    left: auto;
    right: 0px;
    background: var(--RedColor);
    color: #fff;
    font-weight: 500;
    font-size: 8px;
    padding: 1px 3px;
    border-radius: 6px;
    z-index: 500;
    width: auto;
}

.survey-feedback-item .col-xl-3 button div {
    width: 36px;
    position: relative;
}

.survey-feedback-item {
    border-bottom: 1px dashed #ababab;
    width: 100%;
    position: relative;
    padding-bottom: 6px;
    margin-bottom: 6px;
}

.survey-feedback-item:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.survey-feedback-item h6 {
    width: 100%;
    padding: 0;
    margin: 10px 0px 4px 0px;
    font-size: 14px;
    font-weight: 500;
    color: var(--LightTextColor);
}

.featured-post-wrapper {
    width: 100%;
    border: 1px solid #d9dfe5;
    margin-top: 20px;
    position: relative;
    min-height: auto;
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
}

.featured-post-wrapper .tagheading {
    width: 100%;
    padding: 0;
    margin: 0px 0px 12px 0px;
    position: relative;
    font-weight: bold;
    font-size: 15px;
}

.featured-post-wrapper .tagheading button {
    float: right;
    padding: 0;
    margin: 0px 0px 0px 0px;
    background: none;
    border: none;
    outline: none;
    font-size: 13px;
    color: var(--RedColor);
    text-decoration: underline;
    font-weight: 500;
}

.featured-post-wrapper .featured-post-item {
    width: 100%;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    position: relative;
}

.featured-post-wrapper .featured-post-item img {
    width: 100%;
    height: auto;
}

.featured-post-wrapper .featured-post-item h6 {
    width: 100%;
    padding: 0;
    margin: 14px 0px 0px 0px;
    text-align: left;
    font-size: 14px;
    color: var(--LightTextColor);
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.sidebar-active {
    padding-left: 240px;
    transition-duration: 0.3s;
}

.sidebar-active .sidebar-menu {
    left: 0px;
}

.sidebar-menu {
    width: 220px;
    height: 100vh;
    position: fixed;
    left: -240px;
    top: 0px;
    z-index: 1020;
    background: #f6f7fa;
    padding-top: 50px;
    transition-duration: 0.3s;
}

.goog-te-combo {
    width: 100%;
    height: 24px;
    padding-left: 8px;
    border: 1px solid #666666;
}

.goog-te-combo:focus {
    border-color: var(--RedColor);
}

#google_translate_element {
    padding-left: 10px;
    margin-top: 16px;
    margin-bottom: 15px;
}

#google_translate_element select {
    border: 1px solid #666666;
    outline: none !important;
    background: none;
    border-radius: 2px;
    color: var(--TextColor);
}

.taglah-post-footer .row,
.survey-feedback-item .col-xl-3 .row {
    margin: 0px;
}

.taglah-post-footer .col,
.survey-feedback-item .row .col {
    padding: 0px;
}

.dropdownstyle .dropdown-item {
    font-size: 14px;
    padding: 5px 10px;
    line-height: normal;
}

.dropdownstyle .dropdown-item:hover,
.dropdownstyle .dropdown-item:focus {
    background: var(--RedColor);
    color: #fff;
}

.gotobtn {
    font-size: 14px !important;
    font-weight: 600;
    padding-bottom: 3px;
}

.gotobtn i {
    margin-left: 5px;
}

.goto-groupbtn {
    display: inline-block;
}

.goto-groupbtn .dropdownstyle:hover a {
    background: #fff;
}

.goto-groupbtn .dropdownstyle a {
    width: 100%;
    text-align: center;
}

.goto-groupbtn .dropdownstyle a img {
    display: inline-block;
    margin: 0px;
}

.search-employee-btn {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    text-align: left;
    background: var(--RedColor);
    color: #fff;
    position: relative;
    height: 50px;
    margin-top: 20px;
    font-weight: 500;
    padding-left: 20px;
    font-size: 15px;
}

.search-employee-btn .search-icon {
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0px;
    right: 0px;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
}

.featured-resume-wapper {
    width: 100%;
    border: 1px solid #d9dfe5;
    margin-top: 20px;
    position: relative;
    min-height: auto;
    background: #fff;
    padding: 20px;
}

.featured-resume-wapper .tagheading {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    font-weight: bold;
    font-size: 15px;
}

.featured-resume-wapper .tagheading button {
    float: right;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    font-size: 13px;
    color: var(--RedColor);
    text-decoration: underline;
    font-weight: 500;
}

.resume-item-con {

    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 12px;
    border: none;
    outline: none;
    background: none;
}

.resume-item-con span {
    width: 100%;
    display: block;
}

.resume-item-con span img {
    width: 100%;
    padding: 0;
    margin: 0;
    object-fit: cover;
}

.resume-item-con h5 {
    width: 100%;
    padding: 0;
    margin: 10px 0px 2px 0px;
    font-size: 14px;
    font-weight: 600;
}

.resume-item-con h6 {
    width: 100%;
    padding: 0;
    margin: 0px 0px 0px 0px;
    font-size: 12px;
    color: #666666;
    font-weight: normal;
}

.statitics-wrapper {
    margin-top: 20px;
}

.statitics-wrapper button {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0;
    border: none;
    outline: none;
    background: #e4e8ed;
    text-align: center;
    height: 95px;
}

.statitics-wrapper button h6 {
    width: 100%;
    font-size: 13px;
    color: #5b5d5f;
    padding: 0;
    margin: 0;
    text-align: center;
}

.statitics-wrapper button h2 {
    width: 100%;
    font-size: 22px;
    font-weight: 900;
    color: var(--TextColor);
    padding: 0;
    margin: 0;
    text-align: center;
}

.sidebar-logo-con {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.sidebar-logo-con span {
    width: 100%;
    height: 100px;
    text-align: center;
    display: block;
    padding-left: 10px;
}

.sidebar-logo-con span img {
    max-height: 100px;
    min-height: 100px;
    max-width: 210px;
    object-fit: cover;
}

.sidebar-logo-con h6 {
    width: 100%;
    padding: 0;
    margin: 5px 0px 0px 0px;
    font-size: 12px;
    color: #666666;
    font-weight: normal;
    text-align: center;
}

.sidebar-logo-con h4 {
    width: 100%;
    padding: 0px;
    margin: 10px 0px 2px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.featured-job-image {
    display: block;
    position: relative;
}

.featured-job-image span {
    width: auto;
    height: 45px;
    position: absolute;
    left: 8px;
    top: 8px;
    z-index: 100;
}

.featured-job-image span img {
    height: 45px !important;
    width: auto !important;
    object-fit: cover;
}

.interview-request-btn {
    display: block;
    text-align: right;
}

.interview-request-btn button {
    display: inline-block;
    background: none;
}

.interview-request-btn button.accept-btn {
    background: #d1e7dd !important;
    color: #40775e !important;
    padding: 2px 10px;
    margin: 0;
    font-weight: 500;
    border-radius: 2px;
    font-size: 11px;
    background: #e6e6e6;
    line-height: 16px;
    margin-left: 5px;
    border: none;
    outline: none;
}

.interview-request-btn button.close-btn {
    background: #f8d7da !important;
    color: #a95556 !important;
    padding: 3px 10px 1px 10px;
    margin: 0;
    font-weight: 500;
    border-radius: 2px;
    font-size: 11px;
    background: #e6e6e6;
    line-height: 16px;
    margin-left: 5px;
    border: none;
    outline: none;
}

.interview-request-wrap {
    margin-top: -65px;
}

.suggested-company-wrap {
    width: 100%;
    border: 1px solid #d9dfe5;
    margin-top: 20px;
    position: relative;
    min-height: auto;
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
}

.suggested-company-wrap .tagheading {
    width: 100%;
    padding: 0;
    margin: 0px 0px 12px 0px;
    position: relative;
    font-weight: bold;
    font-size: 15px;
}

.suggested-company-wrap .tagheading button {
    float: right;
    padding: 0;
    margin: 0px 0px 0px 0px;
    background: none;
    border: none;
    outline: none;
    font-size: 13px;
    color: var(--RedColor);
    text-decoration: underline;
    font-weight: 500;
}

.suggested-company-wrap .col-xl-3 span {
    width: 100%;
    border: 1px solid #d9dfe5;
    margin-top: 20px;
    display: block;
    text-align: center;
    padding: 5px;
}

.suggested-company-wrap .col-xl-3 span img {
    max-height: 100px;
    min-height: 100px;
    max-width: 100%;
    display: inline-block;
    object-fit: cover;
}

.sidebar-padding .rightsidebar-wrapper {
    right: 0px;
}

.sidebar-padding .rightsidebar-wrapper .tab-height .chat-typearea {
    right: 0px;
}

.rightsidebar-wrapper {
    width: 415px;
    background: #fff;
    position: fixed;
    top: 0px;
    right: -415px;
    padding-left: 20px;
    padding-right: 20px;
    height: 100vh;
    padding-top: 60px;
    transition-duration: 0.3s;
    z-index: 500;
}

.rightsidebar-wrapper .tab-height {
    height: 100vh;
    background: #fff;
    width: 100%;
    position: relative;
}

.rightsidebar-wrapper .tab-height .chat-typearea {
    position: fixed;
    bottom: 0px;
    right: -415px;
    transition-duration: 0.3s;
    background: #fff;
    width: 415px;
    padding: 0px 20px 10px 20px;
    margin: 0;

}

.rightsidebar-wrapper .tab-height .chat-typearea textarea {
    width: 100%;
    background: #fff;
    padding: 10px 10px 0px 10px;
    margin: 0;
    outline: none;
    border: none;
    height: 60px;
    font-size: 14px;
    line-height: 18px;
    border-top: 1px solid #ced0d4;
}

.rightsidebar-wrapper .tab-height .chat-typearea ul {
    width: 100%;
    padding: 0;
    margin: 0px 0px 0px 0px;
    display: block;
}

.rightsidebar-wrapper .tab-height .chat-typearea ul li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
}

.rightsidebar-wrapper .tab-height .chat-typearea ul li.sendbtn {
    float: right;
    padding: 0;
    margin: 0;

}

.rightsidebar-wrapper .tab-height .chat-typearea ul li.sendbtn button {
    padding: 0px 12px;
    margin: 0px 0px;
    background: var(--RedColor);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border: none;
    outline: none;
    height: 28px;
    line-height: 28px;
    border-radius: 3px;
}

.rightsidebar-wrapper .tab-height .chat-typearea ul li.draftbtn {
    float: right;
    padding: 0;
    margin: 0;
}

.rightsidebar-wrapper .tab-height .chat-typearea ul li.draftbtn button {
    padding: 0px 12px;
    margin: 0px 15px 0px 0px;
    background: #e4e8ed;
    color: #5b5d5f;
    font-size: 14px;
    font-weight: 500;
    border: none;
    outline: none;
    height: 28px;
    line-height: 28px;
    border-radius: 3px;
}

.rightsidebar-wrapper .tab-height .chat-typearea ul li.camerabtn {
    float: left;
    padding: 0;
    margin: 0;
}

.rightsidebar-wrapper .tab-height .chat-typearea ul li.camerabtn button {
    padding: 0px 0px;
    margin: 0px 0px 0px 0px;
    background: none;
    color: #5b5d5f;
    font-size: 14px;
    font-weight: 500;
    border: none;
    outline: none;
    height: 28px;
    line-height: 28px;
    border-radius: 3px;
}

.rightsidebar-wrapper .tab-height .chat-typearea ul li.camerabtn button:hover {
    color: var(--RedColor);
}


.rightsidebar-wrapper .tab-height .chat-typearea ul li.atatch-btn {
    float: left;
    padding: 0;
    margin: 0;
}

.rightsidebar-wrapper .tab-height .chat-typearea ul li.atatch-btn button {
    padding: 0px 0px;
    margin: 0px 0px 0px 0px;
    background: none;
    color: #5b5d5f;
    font-size: 14px;
    font-weight: 500;
    border: none;
    outline: none;
    height: 28px;
    line-height: 28px;
    border-radius: 3px;
    padding-left: 30px;
}

.rightsidebar-wrapper .tab-height .chat-typearea ul li.atatch-btn button:hover {
    color: var(--RedColor);
}

.rightsidebar-wrapper .nav-tabs li a {
    padding: 4px 20px;
    font-size: 14px;
    font-weight: 500;
    color: var(--LightTextColor);
}

.rightsidebar-wrapper .nav-tabs li a.active {
    color: var(--RedColor);
}

.big-video-placeholder {
    width: 100%;
    height: 75vh;
    display: table;
    padding-top: 85px;
    position: relative;
}

.video-holder {
    width: 100%;
    height: 100%;
    position: relative;
    background: var(--LightTextColor);
    overflow: hidden;
}

.video-scroll-wrap {
    width: 100%;
    height: 25vh;
    display: table;
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
    padding-left: 45px;
    padding-right: 45px;
}

.video-navbtns {
    width: 45px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 24px;
    padding-right: 20px;
    border: none;
    outline: none;
    background: none;
    color: var(--LightTextColor);
}

.video-navbtns.rightnav {
    right: 0px;
    left: auto;
    padding-right: auto;
    padding-left: 20px;
}

.video-navbtns:hover {
    color: var(--RedColor);
}

.video-scroll-wrap .row {
    margin-left: -5px;
    margin-right: -5px;
    overflow-y: auto;
    white-space: nowrap;
    display: block;
}

.video-scroll-wrap .row::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}


.video-scroll-wrap .row .col-lg-3 {
    height: 25vh;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 25px;
    display: inline-block;
    white-space: normal;
}

.video-scroll-wrap .smallvideo-holder {
    width: 100%;
    height: 100%;
    /* background: url(../img/video-thumbnail.png) #1c1e2e no-repeat; */
    background-position: center;
    display: block;
    position: relative;
    overflow: hidden;
}



.video-scroll-wrap .smallvideo-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition-duration: 0.3s;
}

.video-scroll-wrap .smallvideo-holder:hover img {
    opacity: 0.2;
}

.video-scroll-wrap .smallvideo-holder h6 {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 15px;
    color: #fff;
    padding: 0px 15px;
    margin: 0;
    z-index: 10;
    font-weight: 500;
    font-size: 15px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.video-scroll-wrap .smallvideo-holder .hand-pop {
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 100;
    color: #fff;
    font-size: 28px;
}

.video-scroll-wrap .smallvideo-holder:hover ul {
    right: 5px;
}

.video-scroll-wrap .smallvideo-holder ul {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0px;
    right: -30px;
    z-index: 100;
    transition-duration: 0.3s;
}

.video-scroll-wrap .smallvideo-holder ul li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.video-scroll-wrap .smallvideo-holder ul li button {
    background: none;
    padding: 0;
    margin: 5px 0px;
    border: none;
    outline: none;
    color: #fff;
    width: 30px;
    height: 30px;
}

.video-scroll-wrap .smallvideo-holder ul li button i {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
    transition-duration: 0.3s;
}

.video-scroll-wrap .smallvideo-holder ul li button:hover i {
    color: var(--RedColor);
}

.videobg {
    background: #f6f7fa;
    min-height: 100vh;
    padding-top: 0px;
}

.sidebar-padding {
    padding-right: 450px;
}

.video-holder:hover .video-buttons {
    bottom: 20px;
}

.video-holder .video-buttons {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    text-align: center;
    bottom: -75px;
    left: 0px;
    transition-duration: 0.3s;
}

.video-holder .video-buttons li {
    display: inline-block;
    padding: 0px 10px;
    margin: 0;
}

.video-holder .video-buttons li button {
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 50%;
    display: block;
    line-height: 48px;
    border: none;
    outline: none;
    text-align: center;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.video-holder .video-buttons li button:hover {
    background: var(--RedColor);
    color: #fff;
}

.video-holder .video-buttons li button:hover i {
    color: #fff;
}

.video-holder .video-buttons li button i {
    color: var(--RedColor);
    text-decoration: none;
}

.big-video-placeholder .selfvideo-holder {
    width: 90px;
    height: 90px;
    background: var(--TextColor);
    position: absolute;
    top: 20px;
    right: 20px;
}

.big-video-placeholder .logo-holder {
    position: absolute;
    left: 20px;
    top: 20px;
    background: #fff;
}

.big-video-placeholder .logo-holder img {
    width: auto;
    height: 60px;
    object-fit: cover;
}

.chatscroll {
    height: 100vh;
    padding-bottom: 195px;
}

.chat-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 5px;
}

::selection {
    background: var(--RedColor);
    color: #fff
}

::-moz-selection {
    background: var(--RedColor);
    color: #fff
}

.chat-wrapper::-webkit-scrollbar {
    width: 4px
}

.chat-wrapper::-webkit-scrollbar-track {
    border-radius: 0
}

.chat-wrapper::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(227, 0, 8, .8);
    -webkit-transition: .6s;
    -moz-transition: .6s;
    -o-transition: .6s;
    transition: .6s
}

.chat-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #e30008
}

.chat-wrapper .chat-right-div {
    width: 100%;
    float: left;
    position: relative;
    margin: 4px 0px;
    position: relative;

}

.chat-wrapper .chat-right-div .chat-date {
    width: 100%;
    text-align: right;
    padding: 0;
    margin: 1px 0px 0px 0px;
    display: block;
    float: right;
    font-size: 10px;
    font-weight: 500;
    opacity: 0.6;
}

.chat-wrapper .chat-right-div span {
    float: right;
    background: var(--RedColor);
    color: #fff;
    font-size: 14px;
    padding: 2px 14px;
    border-radius: 5px;
    border-bottom-right-radius: 0px;
}

.chat-wrapper .chat-left-div {
    width: 100%;
    float: left;
    position: relative;
    margin: 4px 0px;
    position: relative;
    padding-left: 25px;
}

.chat-wrapper .chat-left-div span {
    float: left;
    background: #edeef1;
    color: var(--LightTextColor);
    font-size: 14px;
    padding: 2px 14px;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    position: relative;
}

.chat-wrapper .chat-left-div .chat-date {
    width: 100%;
    text-align: left;
    padding: 0;
    margin: 1px 0px 0px 0px;
    display: block;
    float: right;
    font-size: 10px;
    font-weight: 500;
    opacity: 0.6;
}

.chat-wrapper .chat-left-div .chatpropic {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 0px;
    background: var(--RedColor);
    text-align: center;
    color: #fff;
    font-size: 11px;
    line-height: 22px;
    font-weight: 500;
    z-index: 10;
    overflow: hidden;
}

.chat-wrapper .chat-left-div .chatpropic img {
    width: 100%;
    height: 20px;
    object-fit: cover;
}

.chat-cam-image {
    width: 100px;
    height: auto;
    border: none;
    outline: none;
    border-radius: 4px;
    outline: none;
    display: inline-block;
    overflow: hidden;
    margin-right: 5px;
    background: none;
    margin-top: 14px;
    margin-bottom: 14px;
}

.chat-cam-image img {
    width: 100px;
    height: auto;
    object-fit: cover;
    border-radius: 3px !important;
}

.chat-file {
    border: none;
    outline: none;
    padding: 0;
    margin: 10px 0px;
    position: relative;
    font-weight: 500;
    font-size: 13px;
    color: var(--LightTextColor);
    padding-left: 25px;

}

.chat-file i {
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 20px;
    color: var(--LightTextColor);
    transition-duration: 0.3s;
}

.chat-file:hover,
.chat-file:hover i {
    color: var(--RedColor);
}

.chat-right-div .chat-file {
    background: none;
    color: #fff;
    opacity: 0.8;
}

.chat-right-div .chat-file i {
    color: #fff;
}

.chat-right-div .chat-file:hover {
    opacity: 1;
}

.product-div-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    padding-bottom: 90px;
}

.product-div-wrapper-scroll {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 8px;
}


.product-div-wrapper-scroll::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}

.product-div-wrapper-scroll::-webkit-scrollbar-track {
    border-radius: 0
}

.product-div-wrapper-scroll::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(227, 0, 8, .8);
    -webkit-transition: .6s;
    -moz-transition: .6s;
    -o-transition: .6s;
    transition: .6s
}

.product-div-wrapper-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #e30008
}

.product-div-wrapper .product-div-item {
    margin-top: 15px;
    margin-bottom: 15px;
    float: left;
}

.product-div-wrapper .product-div-item .footerbtns {
    margin-left: -4px;
    margin-right: -4px;
    margin-top: 8px;
}

.product-div-wrapper .product-div-item .footerbtns .col-6 {
    padding-left: 4px;
    padding-right: 4px;
}

.product-div-wrapper .product-div-item .footerbtns button {
    width: 100%;
    background: var(--RedColor);
    color: #fff;
    padding: 4px 0px;
    border: none;
    outline: none;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 500;
}

.product-div-wrapper .product-div-item .product-image-div {
    width: 100%;
    position: relative;
    min-height: 80px;
    padding-left: 110px;
}

.product-div-wrapper .product-div-item .product-image-div span {
    width: 90px;
    height: 80px;
    border-radius: 4px;
    background: #ced0d4;
    position: absolute;
    left: 0px;
    top: 0px;
    overflow: hidden;
}

.product-div-wrapper .product-div-item .product-image-div span img {
    width: 90px;
    height: 80px;
    object-fit: cover;
}

.product-div-wrapper .product-div-item .product-image-div h6 {
    font-size: 14px;
    color: var(--TextColor);
    padding: 5px 0px 0px 0px;
    margin: 0px 0px 10px 0px;
    width: 100%;
}

.product-div-wrapper .product-div-item .product-image-div ul {
    width: 100%;
    padding: 0;
    margin: 0;
    display: block;
}

.product-div-wrapper .product-div-item .product-image-div ul li {
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style: none;
    float: left;
}

.product-div-wrapper .product-div-item .product-image-div ul li button {
    padding: 0px;
    margin: 0px;
    border: 1px solid #ced0d4;
    outline: none;
    background: none;
    width: 26px;
    height: 24px;
    font-size: 12px;
    color: var(--LightTextColor);
    float: left;
}

.product-div-wrapper .product-div-item .product-image-div ul li button:hover {
    color: var(--RedColor);
    border-color: var(--RedColor);
}

.product-div-wrapper .product-div-item .product-image-div ul li input {
    width: 26px;
    height: 24px;
    padding: 0;
    margin: 0;
    text-align: center;
    border: none;
    overflow: hidden;
    background: #fff;
    border-top: 1px solid #ced0d4;
    border-bottom: 1px solid #ced0d4;
    outline: none;
    font-size: 12px;
    float: left;
}

.product-div-wrapper .product-div-item .product-image-div h5 {
    width: 100%;
    padding: 0;
    margin: 3px 0px 0px 0px;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
}

.product-div-wrapper .product-div-item .product-image-div h5 b {
    font-weight: bold;
}

.product-div-wrapper .product-div-item .product-image-div h5 b i {
    font-size: 13px;
    position: relative;
    top: -1px;
    margin-right: 1px;
}



.rightsidebar-wrapper .sidebar-buttons {
    width: 80px;
    height: 34px;
    background: #f6f7fa;
    position: absolute;
    left: -80px;
    top: 50px;
}

.sidebar-buttons button:hover {
    color: var(--RedColor);
}

.sidebar-buttons .sidebar-hide {
    width: 37px;
    height: 34px;
    border: none;
    outline: none;
    background: none;
    /* background: url(../img/sidebar-right.jpg) no-repeat; */
    color: var(--LightTextColor);
    font-size: 14px;
}

.sidebar-buttons .thumbnail-video {
    width: 38px;
    height: 34px;
    border: none;
    outline: none;
    background: none;
    color: var(--LightTextColor);
    font-size: 14px;
}


.wallfooter-wrap {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ced0d4;
    text-align: center;
    position: fixed;
    bottom: 0px;
    min-height: 40px;
    z-index: 999;
}

.wallfooter-wrap .col-lg-5 .row {
    margin-left: 0px;
    margin-right: 0px;
}

.wallfooter-wrap .col-lg-5 {
    position: relative;
}

.wallfooter-wrap .col-lg-5 .search-con {
    width: 0%;
    position: absolute;
    left: 0px;
    min-height: 40px;
    z-index: 990;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.wallfooter-wrap .col-lg-5 .search-con input {
    height: 40px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 15px;
    padding: 0px 20px;
}

.wallfooter-wrap .col-lg-5 .search-con button {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0px;
    fill: #676769;
}

.wallfooter-wrap .col-lg-5 .search-con button:hover {
    fill: #ed1b26;
}

.wallfooter-wrap .col-lg-5 .search-con button svg {
    width: 15px;
    height: 15px;
}

.wallfooter-wrap .col-lg-5 .row .footerbtn-con {
    padding-left: 0px;
    padding-right: 0px;
}

.wallfooter-wrap .col-lg-5 .row .footerbtn-con button svg {
    width: 17px;
    position: relative;
    top: -2px;
    fill: #676769;
}

.wallfooter-wrap .col-lg-5 .row .footerbtn-con button:hover svg {
    fill: #ec1721;
}

.wallfooter-wrap .footerbtn-con button {
    width: 100%;
    text-align: center;
    min-height: 40px;
    border: none;
    outline: none;
    background: none;
    color: #676769;
    font-size: 15px;
    font-weight: normal;
    position: relative;
}

.wallfooter-wrap .footerbtn-con button:hover {
    color: #c10d15;
}

.wallfooter-wrap .footerbtn-con button font {
    width: 18px;
    height: 14px;
    background-color: #ef2546;
    border-radius: 10px;
    font-size: 9px;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 50%;
    font-weight: 900;
    padding-top: 0px;
    color: #fff;
}

.wallfooter-wrap .footerbtn-con button i {
    margin-right: 6px;
    font-size: 16px;
}

.wallfooter-wrap {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ced0d4;
    text-align: center;
    position: fixed;
    bottom: 0px;
    min-height: 40px;
    margin-bottom: env(safe-area-inset-bottom);
    margin-bottom: constant(safe-area-inset-bottom);
    display: none;
    visibility: hidden;
}



.wallfooter-wrap span {
    width: 100%;
    font-size: 11px;
    line-height: normal;
    float: left;
    margin-top: -1px !important;
}

.orderconfirm-wrapper {
    width: 100%;
    height: 100vh;
    padding-bottom: 200px;
}

.productname-item {
    width: 100%;
    position: relative;
    border-bottom: 1px dashed #ced0d4;
    min-height: 25px;
    float: left;
    padding-right: 90px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}



.discount-seperator-line {
    border-top: 2px solid #a1a5ac;
    padding-top: 10px !important;
    border-bottom: none !important;
    margin-top: -11px;
}

.discount-seperator-line span {
    top: 10px !important;
}

.productname-item span {
    position: absolute;
    top: 0px;
    right: 0px;
}

.productname-item span i {
    position: absolute;
    left: 6px;
    top: 6px;
    z-index: 100;
    font-size: 13px;
}

.productname-item span input {
    height: 25px;
    outline: none;
    border: 1px solid #d1d3d7;
    font-size: 14px;
    font-weight: 500;
    width: 80px;
    padding-left: 18px;
}

.productname-item h6 {
    font-size: 14px;
    width: 100%;
    padding: 0;
    margin: 5px 0px 0px 0px;
    float: left;
}

.productname-item h6 div {
    width: 100%;
    text-align: right;
    padding-right: 20px;
}

.orderconfirm-heading {
    position: relative;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 20px;
}

.orderconfirm-heading button {
    float: right;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background: none;
    font-weight: 600;
    font-size: 15px;
    color: var(--RedColor);
}


.rightsidebar-wrapper .rightsidebar-button {
    position: fixed;
    bottom: 0px;
    right: -415px;
    transition-duration: 0.3s;
    background: #fff;
    width: 415px;
    padding: 0px 20px 10px 20px;
    margin: 0;
    z-index: 800;

}

.sidebar-padding .rightsidebar-wrapper .rightsidebar-button {
    right: 0px;
}

.rightsidebar-wrapper .rightsidebar-button button {
    width: 100%;
    border: none;
    outline: none;
    background: var(--RedColor);
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 8px 0px;
}

.rightsidebar-wrapper .rightsidebar-button select {
    width: 100%;
    height: 36px;
    padding-left: 10px;
    font-size: 15px;
    border-radius: 3px;
    border: 1px solid #d1d1d1;
    color: var(--LightTextColor);
    outline: none;
    margin-bottom: 8px;
}

.confirm-order-scroll {
    width: 100%;
    height: 100%;
    overflow: scroll;
}


.confirm-order-scroll::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}

.confirm-order-scroll::-webkit-scrollbar-track {
    border-radius: 0
}

.confirm-order-scroll::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(227, 0, 8, .8);
    -webkit-transition: .6s;
    -moz-transition: .6s;
    -o-transition: .6s;
    transition: .6s
}

.confirm-order-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #e30008
}

.cart-icon-div {
    position: relative;
}

.cart-icon-div span {
    background: var(--RedColor);
    color: #fff;
    font-size: 9px;
    font-weight: 600;
    padding: 0px 3px;
    border-radius: 3px;
    position: absolute;
    top: 5px;
    right: 5px;
}

.order-confirm-item {
    width: 100%;
    position: relative;
    border-bottom: 1px dashed rgb(206, 208, 212);
    min-height: 25px;
    float: left;
    padding-right: 165px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}



.order-confirm-item h6 {
    font-size: 14px;
    width: 100%;
    padding: 0;
    margin: 5px 0px 0px 0px;
    float: left;
}

.order-confirm-item strong {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 15px;
}

.order-confirm-item strong i {
    font-size: 13px;
}



.order-confirm-qty {
    width: auto;
    padding: 0;
    margin: 0;
    display: block;
    position: absolute;
    top: 0px;
    right: 80px;
}

.order-confirm-qty li {
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style: none;
    float: left;
}

.order-confirm-qty li button {
    padding: 0px;
    margin: 0px;
    border: 1px solid #ced0d4;
    outline: none;
    background: none;
    width: 26px;
    height: 24px;
    font-size: 12px;
    color: var(--LightTextColor);
    float: left;
}

.order-confirm-qty li button:hover {
    color: var(--RedColor);
    border-color: var(--RedColor);
}

.order-confirm-qty li input {
    width: 26px;
    height: 24px;
    padding: 0;
    margin: 0;
    text-align: center;
    border: none;
    overflow: hidden;
    background: #fff;
    border-top: 1px solid #ced0d4;
    border-bottom: 1px solid #ced0d4;
    outline: none;
    font-size: 12px;
    float: left;
}

.invoice-totals-wrap {
    border-top: 2px solid #a1a5ac;
    width: 100%;
    float: left;
    padding-top: 30px;
    margin-top: -11px;
    position: relative;
    z-index: 10;
    text-align: right;
}

.invoice-totals-wrap .order-confirm-item:last-child {
    border-bottom: none;
    color: var(--RedColor);
    font-size: 18px !important;
}

.videothumbnail-wrap {
    margin-top: 100px;
    height: auto;
    display: block;
}

.videothumbnail-wrap .row {
    overflow: visible;
    white-space: normal;
    display: flex;
}


.videothumbnail-wrap .row .col-lg-3 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.cv-reg-taglahlogo {
    margin-left: 0px !important;
    margin-top: 8px; 
}

.savedate-sidebar {
    width: 220px;
    height: 100vh;
    background: #ece8e6;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    padding-top: 50px;
    overflow-y: scroll;
}

.savedate-sidebar::-webkit-scrollbar {
    width: 3px;
    height: 0
}

.savedate-sidebar::-webkit-scrollbar-track {
    border-radius: 0
}

.savedate-sidebar::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: var(--RedColor);
    -webkit-transition: all .6s;
    -moz-transition: all .6s;
    -o-transition: all .6s;
    transition: all .6s
}

.savedate-sidebar::-webkit-scrollbar-thumb:hover {
    background-color: var(--RedColor);
}

.cv-bodypadding {
    padding-left: 240px;
}

.savedate-sidebar .nav-link {
    border-radius: 0px;
    position: relative;
    padding: 0px;
    min-height: 50px;
    line-height: 50px;
    margin: 0;
}

.savedate-sidebar .nav-link:hover h6,
.savedate-sidebar .nav-link:hover i {
    color: var(--PrimaryColor);
}

/*
.savedate-sidebar .nav-link:after {
    width: 18px;
    height: 18px;
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -9px;
    right: -5px;
    z-index: 900;
    transform: rotate(45deg);
    display: none;
    visibility: hidden;
    transition-duration: 0.3s;
}

.savedate-sidebar .nav-link.active:after {
    display: block;
    visibility: visible;
}
*/

.savedate-sidebar .nav-link span {
    width: 36px;
    height: 36px;
    /* background: url(../img/admin-icons.png) no-repeat #dbd6d3; */
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 7px;
}

.savedate-sidebar .nav-link span.groom {
    background-position-x: -36px;
}

.savedate-sidebar .nav-link span.appearance {
    background-position-x: -72px;
}

.savedate-sidebar .nav-link span.bannerimage {
    background-position-x: -108px;
}

.savedate-sidebar .nav-link span.whenwhere {
    background-position-x: -144px;
}

.savedate-sidebar .nav-link span.share {
    background-position-x: -360px;
}

.savedate-sidebar .nav-link span.regards {
    background-position-x: -396px;
}


.savedate-sidebar .nav-link span.greetings {
    background-position-x: -324px;
}

.savedate-sidebar .nav-link span.gallery {
    background-position-x: -180px;
}

.savedate-sidebar .nav-link span.videos {
    background-position-x: -216px;
}

.savedate-sidebar .nav-link span.invitation {
    background-position-x: -252px;
}

.savedate-sidebar .nav-link span.livestreaming {
    background-position-x: -288px;
}

.savedate-sidebar .nav-link span.selectevent {
    background-position-x: -432px;
}


.savedate-sidebar .nav-link.active span {
    /* background-color: #fff; */
}

.savedate-sidebar .nav-link h6 {
    width: 100%;
    position: relative;
    font-weight: 500;
    font-size: 13px;
    color: #232323;
    line-height: normal;
    padding: 18px 0px 0px 0px;
    margin: 0;
    padding-left: 55px;

}



.savedate-sidebar .nav-link.active i,
.savedate-sidebar .nav-link.active h6 {
    color: #fff;
}

.innerpage-heading {
    width: 100%;
    margin-bottom: 30px;
    display: block;
}

.innerpage-heading hr {
    width: 100%;
    padding: 0;
    margin: 12px 0px 0px 0px;
    border: none;
    height: 1px;
    background: #e0dbd8;
    position: relative;
    overflow: hidden;
}

.innerpage-heading hr:before {
    height: 1px;
    background: var(--RedColor);
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    animation: headingcolor 8s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
}

@keyframes headingcolor {
    0% {
        width: 0%;
        left: 0%;
    }

    25% {
        width: 50%;
        left: 0%;
    }

    50% {
        width: 00%;
        left: 50%;

    }

    75% {
        width: 100%;
        left: 50%;

    }

    100% {
        width: 0%;
        left: 100%;
    }
}

.innerpage-heading h2 {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}

.innerpage-heading .col-lg-4 {
    text-align: right;
}

.innerpage-heading .col-lg-4 .page-appearance {
    width: 20px;
    height: 20px;
    border: none;
    padding: 10px;
    margin: 0px 10px 0px 20px;
    outline: none;
    display: inline-block;
    background: none;
    float: right;
    color: var(--TextColor);
    position: relative;
    top: -8px;
    z-index: 100;
}

.innerpage-heading .col-lg-4 label {
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    margin: 0px 0px 0px 0px;
}

.innerpage-heading .col-lg-4 label input {
    margin-right: 5px;
    position: relative;
    top: 2px;
}

.input-item label {
    padding: 0px;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-weight: 500;
}

.input-item {
    margin: 4px 0px;
}

.input-item input,
.input-item textarea,
.input-item select {
    background: none !important;
    font-size: 15px;
}




.nav-link.active i {
    color: #fff !important;
}


.cvfooter-wrap {
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 990;
    bottom: 0px;
    left: 0px;
    padding-right: 0px;
    padding-left: 220px;
    padding-top: 0px;
    padding-bottom: 8px;
    z-index: 999;
}

.cvfooter-wrap .col-lg-6:last-child {
    text-align: right;
}

.cvfooter-wrap .save-btn {
    padding: 0px 16px;
    margin: 0px;
    min-height: 36px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    border: none;
    outline: none;
    color: #fff;
    margin-right: 20px;
    background: var(--PrimaryColor);
    cursor: pointer;
}

.cvfooter-wrap .skip-btn {
    padding: 0px 16px;
    margin: 0px;
    min-height: 36px;
    background: none;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px;
    color: #939aa9;
    cursor: pointer;

}

.cvfooter-wrap .skip-btn:hover {
    color: var(--PrimaryColor);
}


.prev-btn {
    padding: 0px 0px;
    margin: 0px;
    min-height: 36px;
    background: none;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px;
    margin-left: 20px;
    cursor: pointer;
    color: var(--TextColor);
}

.prev-btn:hover {
    color: var(--PrimaryColor);
}

.prev-btn i {
    margin-right: 2px;
}

.cvfooter-wrap .progressbar {
    width: 100%;
    height: 1px;
    position: relative;
    left: 0px;
    top: 0px;
    margin-bottom: 8px;
    background: rgba(255, 56, 1, 1);
    background: -moz-linear-gradient(left, rgba(255, 56, 1, 1) 0%, rgba(255, 234, 1, 1) 50%, rgba(13, 143, 1, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255, 56, 1, 1)), color-stop(50%, rgba(255, 234, 1, 1)), color-stop(100%, rgba(13, 143, 1, 1)));
    background: -webkit-linear-gradient(left, rgba(255, 56, 1, 1) 0%, rgba(255, 234, 1, 1) 50%, rgba(13, 143, 1, 1) 100%);
    background: -o-linear-gradient(left, rgba(255, 56, 1, 1) 0%, rgba(255, 234, 1, 1) 50%, rgba(13, 143, 1, 1) 100%);
    background: -ms-linear-gradient(left, rgba(255, 56, 1, 1) 0%, rgba(255, 234, 1, 1) 50%, rgba(13, 143, 1, 1) 100%);
    background: linear-gradient(to right, rgba(255, 56, 1, 1) 0%, rgba(255, 234, 1, 1) 50%, rgba(13, 143, 1, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3801', endColorstr='#0d8f01', GradientType=1);
}

.cvfooter-wrap .progressbar hr {
    height: 1px;
    position: absolute;
    top: 0px;
    right: 0px;
    border: none;
    background: #d9dfe5;
    width: auto;
    padding: 0;
    margin: 0;
}

.cvfooter-wrap .progressbar hr:before {
    content: '20%';
    position: absolute;
    top: -15px !important;
    left: -25px;
    top: 0px;
    font-size: 11px;
    font-weight: 700;
}




.appearance-item {
    width: 100%;
    position: relative;
    margin: 15px 0px;
}

.appearance-item .theme-image {
    width: 100%;
    position: relative;
}

.appearance-item .theme-image img {
    width: 100%;
    height: auto;
}

.appearance-item .theme-image span {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 100;
    font-size: 13px;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.9);
    padding: 3px 8px;
}

.appearance-item .row {
    margin: 0px;
    border: 1px solid #d9dfe5;
    border-top: none;
}

.appearance-item .row .col-6 {
    padding: 0;
}

.appearance-item .row .col-6:first-child {
    border-right: 1px solid #d9dfe5;
}

.appearance-item .row .col-6 button,
.appearance-item .row .col-12 button {
    width: 100%;
    border: none;
    padding: 14px 0px;
    margin: 0;
    outline: none;
    background: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.appearance-item .row .col-6 button:hover,
.appearance-item .row .col-12 button:hover {
    color: var(--PrimaryColor);
}

.appearance-item.active .row .col-6 button,
.appearance-item.active .row .col-12 button {
    background: var(--PrimaryColor);
    color: #fff;
    cursor: pointer;

}

.appearance-item.active .row .col-6:first-child button {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.appearance-item.active .row {
    border-color: var(--PrimaryColor);
}

.appearance-item.active .row .col-6:first-child {
    border-right: none;

}

.tab-pane {
    padding-bottom: 75px;
}

.customise-wrapper {
    width: 100%;
    height: auto;
    border-radius: 3px;
    background: #ece8e6;
    float: left;
}

button {
    cursor: pointer;
}

.customise-wrapper .customize-btn {
    width: 100%;
    padding: 12px 20px;
    margin: 0;
    border: none;
    outline: none;
    text-align: left;
    background: none;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #e0dbd8;
    position: relative;
    transition-duration: 0.3s;
    color: var(--TextColor);
}

.customise-wrapper .customize-btn i {
    float: right;
    position: relative;
    top: 3px;
}

.customise-wrapper .customize-btn:hover {
    background: var(--PrimaryColor);
    color: #fff;
    padding-right: 45px;
}

.customise-wrapper .reset-btn {
    width: 100%;
    padding: 12px 20px;
    margin: 0;
    border: none;
    outline: none;
    text-align: center;
    background: none;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    color: var(--headingColor);
}

.customise-wrapper .reset-btn i {
    margin-right: 5px;
}


.customise-wrapper .reset-btn:hover {
    color: var(--PrimaryColor);
}

.customise-wrapper h3 {
    width: 100%;
    padding: 20px 20px 10px 20px;
    margin: 0;
    font-size: 20px;
}

.customise-wrapper hr {
    width: 100%;
    padding: 0;
    margin: 0;
    height: 1px;
    border: none;
    background: #e0dbd8;
    position: relative;
    overflow: hidden;
}

.customise-wrapper hr:before {
    height: 1px;
    background: var(--PrimaryColor);
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    animation: headingcolor 8s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
}

.preview-wrapper {
    /* background: #ece8e6;*/
    width: 100%;
    height: calc(100vh - 225px);
    position: relative;
    text-align: center;
    overflow: hidden;
}

.desktop-mobile-btns {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 20px;
    z-index: 900;
    text-align: center;
    padding: 0;
    margin: 0;
}

.desktop-mobile-btns li {
    list-style: none;
    display: inline-block;
    padding: 0px 10px;
    margin: 0;
}

.desktop-mobile-btns li a {
    background: #fff;
    padding: 0;
    margin: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    display: block;
    color: #959697;

    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.desktop-mobile-btns li a:hover {
    color: var(--PrimaryColor);
}

.desktop-mobile-btns .active a {
    color: var(--PrimaryColor);
}

.input-item .input-list {
    width: 100%;
    padding: 0;
    margin: 5px 0px 0px 0px;
}

.input-item .input-list li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
}

.input-item .input-list li label {
    position: relative;
    padding-left: 22px;
}

.input-item .input-list li label input {
    position: absolute;
    left: 0px;
    top: 3px;
}

.enterskill-con {
    width: 100%;
    position: relative;
    padding-right: 80px;
    margin-bottom: 20px;
}

.enterskill-con button {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 100;
    height: 37px;
    border: none;
    outline: none;
    background: var(--SkillsColor);
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    width: 80px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}


.enterskill-con .input-item {
    padding: 0;
    margin: 0;
}

.enterskill-con .row {
    margin: 0px;
}

.enterskill-con .row .col-8,
.enterskill-con .row .col-4 {
    padding: 0;
    position: relative;
}

.enterskill-con .row .col-8 input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

}

.enterskill-con .row .col-4 input {
    border-radius: 0px;
    border-left: transparent;
    border-right: none;
}

.skill-item-con {
    width: 100%;
    height: 40px;
    background: #e4e8ed;
    position: relative;
    display: table;
    padding: 0px 40px 0px 15px;
    margin-top: 10px;
}

.skill-item-con button {
    width: 40px;
    height: 40px;
    background: none;
    position: absolute;
    top: 0px;
    right: 0px;
    border: none;
    outline: none;
    font-size: 15px;
}

.skill-item-con button:hover {
    background: var(--SkillsColor);
    color: #fff;
}

.skill-item-con h6 {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
    display: table-cell;
    vertical-align: middle;
    line-height: normal;
}

#languages .enterskill-con button {
    background: var(--LanguageColor);
}

#languages .skill-item-con button:hover {
    background: var(--LanguageColor);
}

.experience-item {
    width: 100%;
    background: #e4e8ed;
    padding: 0;
    margin: 0;
    display: block;
    position: relative;
}

.addnew-btn {
    position: relative;
    z-index: 100;
    height: 37px;
    border: none;
    outline: none;
    background: var(--PrimaryColor);
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    border-radius: 3px;
}


.hobbies-listing h6 i {
    position: absolute;
    left: 10px;
    top: 13px;
}

.hobbies-listing h6 {
    padding-left: 25px;
}

.family-item {
    width: 100%;
    background: #e4e8ed;
    display: table;
    position: relative;
    min-height: 76px;
    padding-left: 85px;
    padding-right: 38px;
    float: left;
    margin-top: 15px;
}

.family-item .family-content {
    display: table-cell;
    vertical-align: middle;
}

.family-item .family-buttons {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 100;
    width: 38px;
    border-left: 1px solid #d9dfe5;
}

.family-item .family-buttons span {
    display: none;
    visibility: hidden;
}

.family-item .family-buttons button {
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 38px;
    height: 38px;
    border: none;
    outline: none;
    line-height: 38px;
    text-align: center;
    font-size: 13px;
    float: right;
    background: none;

}

.family-item .family-buttons button:last-child {
    border-top: 1px solid #d9dfe5;
}

.family-item .family-buttons button:hover {
    background: var(--FamilyColor);
    color: #fff;
}

.family-item h4 {
    width: 100%;
    padding: 0;
    margin: 0px 0px 4px 0px;
    font-size: 15px;
    font-weight: 500;
}

.family-item h6 {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 13px;
    font-weight: normal;
}

.family-item .family-image {
    width: 66px;
    height: 66px;
    /* background: url(../img/family-icon.png) no-repeat #d1d7df; */
    background-position: center;
    position: absolute;
    left: 5px;
    top: 5px;
    overflow: hidden;
}

.family-item .family-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.accod-style .card {
    background: none;
    border-color: #d9dfe5;
    margin-top: 10px;
    border-radius: 0px;
}

.accod-style .card .btn-link {
    width: 100%;
    text-align: left;
    padding: 8px 125px 8px 15px;
    margin: 0;
    box-shadow: none;
    outline: none;
    color: #232323;
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
}

.accod-style .card hr {
    width: 100%;
    padding: 0;
    margin: 0;
    height: 1px;
    border: none;
    background: #d9dfe5;
}

.accod-style .card .card-body {
    width: 100%;
    padding: 0px 15px 15px 15px;
    margin: 0px 0px;
}

.accod-style .card .card-body p {
    font-size: 15px;
    padding: 0;
    line-height: 22px;
}

.accod-style .card .card-body p:last-child {
    margin-bottom: 0px;
}

.accod-style .card .card-body h6 {
    padding: 0;
    margin: 15px 0px 10px 0px;
}

.accod-style .card .card-body h6 span {
    font-size: 13px;
    display: inline-block;
    background: var(--EducationColor);
    color: #fff;
    margin-right: 8px;
    padding: 3px 8px;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.accod-style .card .card-body h6 span i {
    font-size: 10px;
    position: relative;
    margin-right: 4px;
    top: -1px;
}

.accod-style .card .card-body h5 {
    width: 100%;
    padding: 0;
    margin: 25px 0px 5px 0px;
    font-size: 16px;
}

#experience .accod-style .card .card-body h5 {
    color: var(--ExperienceColor);
}


.accod-style .card {
    position: relative;
}

.accod-style .card .education-buttons {
    width: auto;
    position: absolute;
    top: 0px;
    right: 0px;
}

.accod-style .card .education-buttons span {
    display: none;
    visibility: hidden;
}

.accod-style .card .education-buttons button {
    width: 35px;
    height: 35px;
    background: none;
    padding: 0;
    margin: 3px 4px 0px 0px;
    display: inline-block;
    border: none;
    outline: none;
    float: right;
    font-size: 14px;
}

.accod-style .card .education-buttons button:hover {
    background: var(--EducationColor);
    color: #fff;
}

.accod-style .card .collapsed .downicon {
    transform: rotate(0deg);
    top: 13px;
}

.accod-style .card .downicon {
    transform: rotate(180deg);
    position: absolute;
    top: 15px;
    right: 100px;
}

#experience .accod-style .card .education-buttons button:hover,
#experience .accod-style .card .card-body h6 span {
    background: var(--ExperienceColor);
}


#achivements .accod-style .card .education-buttons button:hover,
#achivements .accod-style .card .card-body h6 span {
    background: var(--AchivementsColor);
}

.testimonials-item {
    width: 100%;
    display: block;
    background: #e4e8ed;
    position: relative;
    padding: 0px 20px 20px 20px;
    margin-top: 15px;
}

.testimonials-item p {
    font-size: 15px;
    padding: 0;
    line-height: 22px;
    text-align: left;
    font-style: italic;
    color: #232323;
}

.testimonials-item h6 {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.testimonials-item h5 {
    width: 100%;
    padding: 20px 0px 10px 0px;
    margin: 0px 0px 20px 0px;
    position: relative;
    font-size: 14px;
    border-bottom: 1px solid #d9dfe5;
    min-height: 48px;
}

.testimonials-item.active h5:before {
    background: var(--TestimonialsColor);
    color: #fff;
    padding: 3px 15px;
    margin: 0;
    font-size: 13px;
    border-radius: 3px;
    content: 'Active';
    position: absolute;
    left: 0px;
    top: 15px;
}

.testimonials-item.draft h5:before {
    background: #d9dfe5;
    color: #86878e;
    padding: 3px 15px;
    margin: 0;
    font-size: 13px;
    border-radius: 3px;
    content: 'Draft';
    position: absolute;
    left: 0px;
    top: 15px;
}

.testimonials-item.pending h5:before {
    background: #d9dfe5;
    color: #86878e;
    padding: 3px 15px;
    margin: 0;
    font-size: 13px;
    border-radius: 3px;
    content: 'Pending';
    position: absolute;
    left: 0px;
    top: 15px;
}



.testimonials-item .dropdown {
    position: absolute;
    top: 15px;
    right: 0px;
    z-index: 100;
    color: #232323;
}

.testimonials-item .dropdown .dropdown-icon {
    color: #232323;
    font-size: 18px;
    padding: 10px 10px;
}

.testimonials-item .dropdown .dropdown-menu a {
    padding: 5px 15px;
    margin: 0;
    font-size: 14px;
}

.testimonials-item .dropdown .dropdown-menu a:hover {
    background: var(--TestimonialsColor);
    color: #fff;
}

#membership .skill-item-con button:hover,
#membership .enterskill-con button {
    background: var(--MembershipColor);
}

#membership .enterskill-con .col-12 {
    padding-right: 0px;
    padding-left: 0px;
}

#membership .enterskill-con .col-12 input {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

#contact .contact-heading {
    width: 100%;
    padding: 0;
    margin: 30px 0px 0px 0px;
    font-size: 16px;
    line-height: normal;
    color: var(--ContactColor);
}

#contact .col-lg-12 textarea {
    height: 100px;
}

#gallery .nav-pills a {
    color: var(--GalleryColor);
    font-size: 14px;
    font-weight: 500;
    padding: 3px 25px;
    border-radius: 3px;
}

#gallery .nav-pills a.active {
    background: var(--GalleryColor);
    color: #fff;
}

#gallery .nav-pills {
    margin-bottom: 20px;
}

#gallery .enterskill-con .col-12 {
    padding-left: 0px;
    padding-right: 0px;
}

#gallery .enterskill-con .col-12 input {
    border-right: none;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

#gallery .enterskill-con button {
    background: var(--GalleryColor);
}

#gallery .enterskill-con {
    margin: 0px;

}

.youtube-video-item {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    margin-top: 15px;
}



.youtube-video-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.youtube-vide-wrap {
    margin-left: -8px;
    margin-right: -8px;
}

.youtube-vide-wrap .col-xl-2 {
    padding-left: 8px;
    padding-right: 8px;
}

#gallery .tab-pane {
    padding-bottom: 0px;
}

#gallery .addnew-btn {
    background: var(--GalleryColor);
}

#gallery .gallery-item {
    width: 100%;
    display: block;
    position: relative;
}

#gallery .gallery-item img {
    width: 100%;
    object-fit: cover;
    height: 160px;
}

#gallery .gallery-item button,
#gallery .youtube-video-item button {
    border: none;
    height: 30px;
    width: 50%;
    line-height: 30px;
    position: relative;
    border-bottom: 1px solid #d9dfe5;
    float: left;
    background: none;
    text-align: center;
    font-size: 13px;
    border-right: 1px solid #d9dfe5;
}

#gallery .gallery-item button:last-child,
#gallery .youtube-video-item button:last-child {
    border-right: none;
}

#gallery .gallery-item button i {
    margin-right: 4px;
}

#gallery .gallery-buttons {
    border-left: 1px solid #d9dfe5;
    border-right: 1px solid #d9dfe5;
    display: block;
    width: 100%;
    float: left;
}

#gallery .gallery-item button:hover,
#gallery .youtube-video-item button:hover {
    background: var(--GalleryColor);
    color: #fff;
    border-color: var(--GalleryColor);
}

.gallery-images {
    margin-left: -8px;
    margin-right: -8px;
}

.gallery-images .col-xl-2 {
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 15px;
}

.customise-heading {
    width: 100%;
    position: relative;
    padding: 22px 0px 12px 55px;
}

.customise-heading .backbtn {
    padding: 15px;
    margin: 0px;
    border: none;
    outline: none;
    background: none;
    position: absolute;
    left: 3px;
    top: 10px;
    font-size: 24px;
}

.customise-heading .backbtn:hover {
    color: var(--PrimaryColor);
}

.customise-heading h3 {
    width: 100%;
    padding: 0;
    margin: 0;
}

.customise-heading h6 {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 13px;
}

.customize-content {
    padding: 10px 20px;
    width: 100%;
    float: left;
    overflow-y: scroll;
    max-height: calc(100vh - 300px);
}


.customize-content::-webkit-scrollbar {
    width: 4px;
    height: 0
}

.customize-content::-webkit-scrollbar-track {
    border-radius: 0
}

.customize-content::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: var(--PrimaryColor);
    -webkit-transition: all .6s;
    -moz-transition: all .6s;
    -o-transition: all .6s;
    transition: all .6s
}



.customize-item label {
    padding: 0px;
    margin: 0px 0px 4px 0px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
}

.customize-item .select-img {
    width: 100%;
    background: none;
    text-align: center;
    padding: 10px 0px;
    margin: 0;
    outline: none;
    color: #bec1c5;
    font-size: 14px;
    border: 1px dashed #bec1c5;
    border-radius: 4px;
}

.customize-item .select-img:hover {
    color: var(--PrimaryColor);
    border-color: var(--PrimaryColor);
}

.customize-item .select-color {
    width: auto;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background: none;

}

.customize-item .select-color span {
    width: 30px;
    height: 30px;
    background: var(--PrimaryColor);
    float: left;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.customize-item .select-color h6 {
    float: left;
    padding: 0px 10px;
    margin: 0;
    font-size: 13px;
    border: 1px solid #b5b8bc;
    border-left: none;
    height: 30px;
    line-height: 30px;
    color: #b5b8bc;
    font-weight: normal;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.customize-item .select-color:hover h6 {
    color: var(--PrimaryColor);
    border-color: var(--PrimaryColor);
}

.customize-item {
    width: 100%;
    margin: 8px 0px;
    float: left;
}

.display-bg-image img {
    width: 100%;
    height: auto;
}

.display-bg-image {
    width: 100%;
    float: left;
}

.display-bg-image button {
    border: none;
    height: 45px;
    width: 50%;
    line-height: 30px;
    position: relative;
    border-bottom: 1px solid #e0dbd8;
    float: left;
    background: none;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    border-right: 1px solid #e0dbd8;
}

.display-bg-image .gallery-item button:last-child {
    border-right: none;
}

.display-bg-image button i {
    margin-right: 4px;
}

.display-bg-image .gallery-buttons {
    border-left: 1px solid #e0dbd8;
    border-right: 1px solid #e0dbd8;
    display: block;
    width: 100%;
    float: left;
}

.display-bg-image button:hover {
    background: var(--PrimaryColor);
    color: #fff;
    border-color: var(--PrimaryColor);
}

.orseperator {
    width: 100%;
    text-align: center;
    position: relative;
    font-size: 14px;
    padding: 0px;
    margin: 10px 0px 5px 0px;
    float: left;
}

.orseperator b {
    position: relative;
    z-index: 100;
    background: #ece8e6;
    padding: 0px 20px;
}

.orseperator:before {
    width: 100%;
    height: 1px;
    padding: 0px;
    margin: 0px;
    background: #cfd5dd;
    position: absolute;
    left: 0px;
    top: 10px !important;
    content: '';
    z-index: 10;
}

.bg-imagestyle {
    margin: 0px;
    padding: 0;
}

.bg-imagestyle li {
    padding: 0;
    list-style: none;
}

.bg-imagestyle li input {
    margin-right: 5px;
    float: left;
    position: relative;
    top: 3px;
}

.customise-heading .dropleft {
    position: absolute;
    top: 20px;
    right: 12px;
    z-index: 800;
}

.customise-heading .dropleft .drop-btn {
    padding: 10px;
    margin: 0;
    background: none;
    outline: none;
    border: none;
    color: var(--TextColor);
}

.customise-heading .dropleft .drop-btn:hover {
    color: var(--PrimaryColor);
}

.customise-heading .dropdown-menu {
    padding: 5px 0px;
    margin: 0px;
}

.customise-heading .dropdown-menu .dropdown-item {
    padding: 3px 10px;
    margin: 0;
    font-size: 14px;
    width: 100%;
}

.customise-heading .dropdown-menu .dropdown-item:hover {
    background: var(--PrimaryColor);
    color: #fff;
}







input[type="radio"] {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
}

#radiostyle {
    accent-color: var(--RedColor);
}

.custom-radio {
    appearance: none;
    -webkit-appearance: none;
    border: 0.2rem solid transparent;
    border-radius: 50%;
}

#bg-style,
#bg-style2 {
    accent-color: var(--PrimaryColor);
}

.background-image-select {
    margin-left: -5px;
    margin-right: -5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.background-image-select .col-4 {
    padding-left: 5px;
    padding-right: 5px;
}

.background-image-select button {
    width: 100%;
    padding: 0;
    margin: 5px 0px;
    background: none;
    outline: none;
    border: none;
    position: relative;
}

.background-image-select button img {
    width: 100%;
    padding: 0;
    margin: 0;
    object-fit: cover;
}

.background-image-select button span {
    width: 25px;
    height: 25px;
    line-height: 25px;
    position: absolute;
    border-radius: 50%;
    padding: 1px 0px 0px 0px;
    left: 5px;
    top: 5px;
    z-index: 100;
    background: #fff;
    font-size: 18px;
    color: var(--PrimaryColor);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.customise-fonts {
    width: 100%;
    padding: 0;
    margin: 0;
}

.customise-fonts li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.customise-fonts li button {
    width: 100%;
    padding: 10px 0px 10px 30px;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    position: relative;
    text-align: left;
    font-size: 15px;
    line-height: normal;
}

.customise-fonts li button:before {
    width: 15px;
    height: 15px;
    border: 1px solid #cfd5dd;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    content: '';
    top: 50%;
    margin-top: -8px;
}

.customise-fonts li button:hover {
    color: var(--PrimaryColor);
}

.customise-fonts li.active button:before {
    border-color: var(--PrimaryColor);
    background: var(--PrimaryColor);
}

.customise-fonts li.active button {
    color: var(--PrimaryColor);
}

.upload-photo {
    width: 100%;
    height: auto;
    background: #ece8e6;
    border-radius: 3px;
    margin-top: 10px;
    float: left;
    margin-bottom: 20px;
    overflow: hidden;
}

.upload-photo .profilepic-btn {
    width: 100%;
    float: left;
    text-align: center;
}

.upload-photo .profilepic-btn .edit-delete {
    border: none;
    height: 45px;
    width: 50%;
    line-height: 45px;
    position: relative;
    border-top: 1px solid #e0dbd8;
    float: left;
    background: none;
    text-align: center;
    font-size: 13px;
    border-right: 1px solid #e0dbd8;
    cursor: pointer;
    font-weight: 500;
    color: var(--TextColor);
}

.upload-photo .profilepic-btn .edit-delete i {
    margin-right: 4px;
}

.upload-photo .profilepic-btn .edit-delete:last-child {
    border-right: none;
}

.upload-photo .profilepic-btn .edit-delete:hover {
    background: var(--PrimaryColor);
    color: #fff;
}

.upload-photo .profile-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: inline-block;
    /* background: url(../img/savedate-logo.svg) no-repeat #fddfb7; */
    background-size: 80px 80px;
    background-position: 20px 20px;
    position: relative;
    left: 50%;
    margin-left: -60px;
    margin-top: 30px;
    margin-bottom: 20px;
    overflow: hidden;
}

.upload-photo .profile-pic img {
    width: 100%;
    height: 120px;
    object-fit: cover;
}

.upload-photo .profilepic-btn .edit-delete.upload-btn {
    width: 100%;
}

#download .appearance-item .row .col-6 button:hover,
#download .appearance-item .row .col-12 button:hover {
    color: var(--DownloadColor);
}

#download .appearance-item.active .row .col-6 button,
#download .appearance-item.active .row .col-12 button {
    background: var(--DownloadColor);
    color: #fff;
}

#download .appearance-item.active .row {
    border-color: var(--DownloadColor);
}

.questionnaire-sidebar {
    background: #e4e8ed;
}

.questionnaire-sidebar .menu li a {
    background: none !important;
}

.questionnaire-sidebar .menu {
    margin-top: calc(50vh - 220px);
}

.questionnaire-footer .previous-btn {
    float: left;
    padding: 0px 16px;
    margin: 0px;
    min-height: 36px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    border: none;
    outline: none;
    color: #fff;
    margin-left: 20px;
    background: var(--RedColor);
    min-width: 90px;
}

.questionnaire-footer .next-btn {
    float: right;
    padding: 0px 16px;
    margin: 0px;
    min-height: 36px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    border: none;
    outline: none;
    color: #fff;
    margin-right: 20px;
    background: var(--RedColor);
    min-width: 90px;
}

.questionnaire-footer .col-lg-6 h6 {
    width: 100%;
    padding: 0;
    margin: 10px 0px 0px 0px;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
}

.questionnaire-footer .col-lg-6 h6 span {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #c8c8c8;
    display: none;
    visibility: hidden;
}

.questionnaire-sidebar .timercon {
    top: auto;
    left: 50%;
    margin-left: -70px;
    bottom: 20px;
    position: absolute;
}

.timercon[role="progressbar"] {

    --bg: #d3d9e1;
    --pgPercentage: var(--value);
    animation: growProgressBar 3s 1 forwards;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background:
        radial-gradient(closest-side, #e4e8ed 95%, transparent 0 99.9%, #e4e8ed 0),
        conic-gradient(var(--RedColor) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    font-family: Helvetica, Arial, sans-serif;
    font-size: 28px;
    color: var(--TextColor) !important;
    font-weight: bold;
    color: var(--RedColor);
}

.timercon[role="progressbar"]::before {
    counter-reset: percentage var(--value);
    content: '00:00';
    position: absolute;
    top: 70px;
}

.timercon[role="progressbar"]::after {
    width: 32px;
    height: 39px;
    position: absolute;
    left: 50%;
    margin-left: -16px;
    top: 25px;
    content: '';
    /* background: url(../img/timer.png); */
}

.questionnaire-footer {
    padding-left: 0px;
    transition-duration: 0.3s;
}

.sidebar-active .questionnaire-footer {
    padding-left: 220px;
}

.questions-status {
    width: 100%;
    padding: 0;
    margin: 0;
    display: block;
}

.questions-status li {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
}

.questions-status li button {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 2px 0px;
    background: none;
    outline: none;
    border: 1px solid #d9dfe5;
    font-size: 14px;
    font-weight: 500;
}

.questions-status li button:hover {
    border-color: var(--RedColor);
    color: var(--RedColor);
}

.questions-status li.active button {
    border-color: var(--RedColor);
    background: var(--RedColor);
    color: #fff;
}

.questionnaire-item h6 {
    width: 100%;
    padding: 0;
    margin: 0px 0px 15px 0px;
    font-size: 18px;
    font-weight: 500;
}

.questionnaire-item {
    width: 100%;
    margin-bottom: 35px;
}

.questionnaire-item:last-child {
    margin-bottom: 0px;
}

.questionnaire-item textarea,
.questionnaire-item input,
.questionnaire-item select {
    background: none !important;
    box-shadow: none !important;
}

.questionnaire-item textarea:focus,
.questionnaire-item input:focus,
.questionnaire-item select:focus {
    border-color: var(--RedColor);
}

.radiobuttons {
    width: 100%;
    padding: 0;
    margin: 0;
}

.radiobuttons li {
    width: 100%;
    padding: 0;
    margin: 0px 0px 10px 0px;
    list-style: none;
    position: relative;
}

.radiobuttons li:last-child {
    margin-bottom: 0px;
}

.radiobuttons li label {
    font-size: 15px;
    padding: 0px 0px 0px 26px;
    margin: 0px;
    width: 100%;
    position: relative;
    font-weight: 500;
}

.radiobuttons li label input {
    position: absolute;
    left: 0px;
    top: 3px;
}

.starratingbuttons {
    width: 100%;
    padding: 0;
    margin: 0;
    display: block;
}

.starratingbuttons li {
    width: auto;
    padding: 0;
    margin: 0px 0px10px 0px;
    list-style: none;
    display: inline-block;
    position: relative;
}

.starratingbuttons li button {
    padding: 5px;
    margin: 0;
    border: none;
    outline: none;
    background: none;
    font-size: 16px;
}

.starratingbuttons li button .fa-solid.fa-star {
    color: var(--RedColor);
}

.questionnaire-item .col-xl-3 img {
    width: 100%;
    margin-top: 4px;
    border-radius: 3px;
    object-fit: cover;
}

.questionnare-scroll {
    width: 100%;
    height: calc(100vh - 220px);
    overflow-y: scroll;
    padding-right: 20px;
}

.questionnare-scroll::-webkit-scrollbar {
    width: 6px;
    height: 0
}

.questionnare-scroll::-webkit-scrollbar-track {
    border-radius: 0
}

.questionnare-scroll::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(227, 0, 8, .7);
    -webkit-transition: all .6s;
    -moz-transition: all .6s;
    -o-transition: all .6s;
    transition: all .6s
}

.questionnare-scroll::-webkit-scrollbar-thumb:hover {
    background-color: var(--RedColor)
}

.questionnaire-item td {
    padding: 5px 10px;
    line-height: normal;
    font-size: 15px;
}

.questionnaire-item td:first-child {
    padding-top: 15px;
}

.smileyicons {
    width: 100%;
    padding: 0;
    margin: 0;
    display: block;
}

.smileyicons li {
    list-style: none;
    padding: 0;
    margin: 0px 10px 0px 0px;
    display: inline-block;
}

.smileyicons li:last-child {
    margin-right: 0px;
}

.smileyicons li button {
    padding: 0;
    margin: 0;
    background: none;
    outline: none;
    border: none;
}

.smileyicons li button img {
    width: 38px;
    height: 38px;
}

.smileyicons.active button img {
    opacity: 0.2;
}

.smileyicons.active button.active img {
    opacity: 1;
}

.image-questions img {
    width: 100%;
}

.answered-questions h4 {
    width: 100%;
    padding: 0;
    margin: 0px 0px 8px 0px;
    font-size: 20px;
    font-weight: 500;
}

.getstarted-wrapper {
    width: 100%;
    text-align: center;
    height: calc(100vh - 140px);
    display: table;
}

.getstarted-wrapper .getstarted-middle-align {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

.getstarted-wrapper .getstarted-middle-align h6 {
    width: 100%;
    font-size: 14px;
    padding: 0;
    margin: 30px 0px;
}

.getstarted-wrapper .getstarted-middle-align h6 span {
    padding: 3px 10px;
    margin: 0px;
    border-radius: 3px;
    background: var(--RedColor);
    color: #fff;
}

.getstarted-wrapper .getstarted-middle-align p {
    padding: 0;
    margin: 0;
    line-height: 24px;
    color: #494949;
}

.getstarted-wrapper .getstarted-middle-align ul {
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
}

.getstarted-wrapper .getstarted-middle-align ul li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
}

.getstarted-wrapper .getstarted-middle-align ul li a {
    color: #494949;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 20px;
}

.getstarted-wrapper .getstarted-middle-align ul li a:hover {
    text-decoration: none;
    color: var(--RedColor);
}

.getstarted-wrapper .getstarted-middle-align ul li a i {
    margin-right: 3px;
}

.getstarted-wrapper .getstarted-middle-align .getstartedbtn {
    width: 140px;
    height: 140px;
    border: 3px solid #d3d9e1;
    outline: none;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 20px 0px;
    display: inline-block;
    background: none;
    border-radius: 50%;
}

.getstarted-wrapper .getstarted-middle-align .getstartedbtn h4 {
    color: #d3d9e1;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
}

.getstarted-wrapper .getstarted-middle-align .getstartedbtn h6 {
    color: #d3d9e1;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

.getstarted-wrapper .getstarted-middle-align .getstartedbtn:hover {
    border-color: var(--RedColor);
    color: var(--RedColor);
}

.getstarted-wrapper .getstarted-middle-align .getstartedbtn:hover h4,
.getstarted-wrapper .getstarted-middle-align .getstartedbtn:hover h6 {
    color: var(--RedColor);
}

.goog-logo-link {
    display: none !important;
}

.goog-te-gadget {
    color: transparent !important;
}

.field-items {
    width: 100%;
    padding: 8px 0px;
}

.field-items input,
.field-items select,
.field-items textarea {
    background: none !important;
    font-size: 15px;
    box-shadow: none !important;
    transition-duration: 0.3s;
}

.field-items input:focus,
.field-items select:focus,
.field-items textarea:focus {
    border-color: var(--RedColor);
}

.field-items label {
    padding: 0px;
    margin: 0px 0px 2px 0px;
    font-size: 14px;
    font-weight: 500;
}

.field-items .input-list {
    width: 100%;
    padding: 0;
    margin: 5px 0px 0px 0px;
}

.field-items .input-list li {
    list-style: none;
    padding: 0px 8px 0px 0px;
    margin: 0;
    display: inline-block;
}

.field-items .input-list li label {
    position: relative;
    padding-left: 22px;
    cursor: pointer;
}

.field-items .input-list li label input {
    position: absolute;
    left: 1px;
    top: 3px;
    outline: none;
    width: 13px;
    height: 13px;
    border: none;
    z-index: 900;
    cursor: pointer;
}

.field-items textarea {
    width: 100%;
    height: 100px;

}

.field-items input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #ced4da;
}

.field-items input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: var(--RedColor);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid var(--RedColor);
}

.field-items input[type='checkbox']:after {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #ced4da;
}

.field-items input[type='checkbox']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: var(--RedColor);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid var(--RedColor);
}


.table-styles .table td,
.table-styles .table th {
    padding: 8px 8px;
    font-size: 14px;
}

.table-styles .table-striped tbody tr:nth-of-type(2n+1) {
    background: #eef0f4;
}

.table-styles .table thead th {
    border: none;
    color: #fff;
    background: var(--RedColor);
}

.submitbtn {
    width: 100%;
    padding: 0px 0px;
    margin: 6px 0px;
    height: 37px;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    border: none;
    border-radius: 3px;
    outline: none;
    background: var(--RedColor);
    color: #fff;
    display: inline-block;
}

.submitbtn i {
    margin-right: 5px;
}

.greenbtn {
    background: #28a745;
}

.redbtn {
    background: #dc3545;
}

.yellowbtn {
    background: #ffc107;
    color: #000;
}

.bluebtn {
    background: #17a2b8;
}

.buttons-row {
    margin-left: -6px;
    margin-right: -6px;
}

.buttons-row .col-lg-2 {
    padding-left: 6px;
    padding-right: 6px;
}

.dashboard-header-menu {
    width: auto;
    display: inline-block;
    padding: 0;
    margin: 0px 0px 0px 62px;
}

.dashboard-header-menu li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
}

.dashboard-header-menu li a {
    font-size: 13px !important;
    font-weight: 500;
    color: var(--TextColor);
    padding: 0px 15px;
}

.dashboard-header-menu li a:hover {
    color: var(--RedColor);
    text-decoration: none;
}

.dashboard-wallpost-item {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 12px 0px 0px 0px;

}


.dashboard-wallpost-item .image-holder {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
}

.dashboard-wallpost-item .image-holder img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.dashboard-wallpost-item .image-holder h6 {
    width: 100%;
    padding: 0;
    margin: 12px 0px;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    color: var(--TextColor);

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.dashboard-wallpost-item .image-holder:hover h6 {
    color: var(--RedColor);
}

.dashboard-wallpost-item .taglah-post-footer {
    border-top: 1px solid #d9dfe5;
    border-right: none;
    border-left: none;
}

.wallfooter-wrap .dropdownstyle {
    width: 100%;
    text-align: center;
}

.wallfooter-wrap .dropdownstyle img {
    height: 25px;
    width: auto;
}

.taglah-wallpost {
    width: 100%;
    border: 1px solid #d9dfe5;
    position: relative;
    min-height: auto;
    background: #fff;
    padding: 14px 14px 11px 14px;
    margin-bottom: 10px;
}

.taglah-wallpost .wallpost-header {
    width: 100%;
    position: relative;
    padding-left: 52px;
    padding-right: 75px;
    min-height: 42px;
    margin-bottom: 10px;
}

.taglah-wallpost .wallpost-header .pro-pic {
    width: 42px;
    height: 42px;
    /* background: url(../img/cv-man.jpg) no-repeat #e5e8ed; */
    background-size: 100% 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 50%;
    overflow: hidden;
}

.taglah-wallpost .wallpost-header .pro-pic img {
    width: 42px;
    height: 42px;
    object-fit: cover;
}

.taglah-wallpost .wallpost-header h6 {
    width: 100%;
    padding: 0;
    margin: 0;
    color: var(--LightTextColor);
    font-size: 12px;
    font-weight: normal;
}

.taglah-wallpost .wallpost-header h4 {
    width: 100%;
    padding: 0;
    margin: 3px 0px 2px 0px;
    color: var(--TextColor);
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
}

.taglah-wallpost p {
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: var(--LightTextColor);
    line-height: 20px;
}

.taglah-wallpost .row {
    margin-left: -3px;
    margin-right: -3px;
    margin-top: 10px;
}

.taglah-wallpost .row .col-lg-7,
.taglah-wallpost .row .col-lg-6,
.taglah-wallpost .row .col-lg-5,
.taglah-wallpost .row .col-lg-4,
.taglah-wallpost .row .col-lg-3,
.taglah-wallpost .row .col-lg-2,
.taglah-wallpost .row .col-lg-1,
.taglah-wallpost .row .col-lg-8,
.taglah-wallpost .row .col-lg-9,
.taglah-wallpost .row .col-lg-10,
.taglah-wallpost .row .col-lg-11,
.taglah-wallpost .row .col-lg-12 {
    padding-left: 3px;
    padding-right: 3px;
}

.wallimage {
    width: 100%;
    margin: 0px 0px;
    display: block;
    position: relative;
    overflow: hidden;
    padding: 3px 0px;
}

.fourimg-style .col-lg-4 .wallimage {
    height: 15.33vh;

}

.fourimg-style .col-lg-4 .wallimage img {
    width: 100%;
    height: calc(15.33vh - 6px);
    object-fit: cover;
}

.oneimg-style .col-xl-12 img {
    width: 100%;
    height: 45vh;
    object-fit: cover;
}

.twoimg-style .col-xl-6 img {
    width: 100%;
    height: 30vh;
    object-fit: cover;
}

.threeimg-style .col-xl-8 img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
}

.threeimg-style .col-xl-4 img {
    width: 100%;
    height: calc(20vh - 3px);
    object-fit: cover;
}

.fourimg-style .col-lg-8 .wallimage {
    height: 46vh;
}

.fourimg-style .col-lg-8 .wallimage img {
    width: 100%;
    height: calc(46vh - 6px);
    object-fit: cover;
}

.wall-scroll {
    height: calc(100vh - 70px);
    overflow: scroll;
}

.wallpost-header .wallbtn {
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    background: none;
    color: var(--LightTextColor);
}

.wallpost-header .wallbtn:hover {
    color: var(--RedColor);
}

.wallpost-header .socialshare {
    position: absolute;
    top: 0px;
    right: 35px;
}

.wallpost-header .wallpostsettings {
    position: absolute;
    top: 0px;
    right: 0px;
}

.wallpost-header .dropdown-item i {
    font-size: 12px;
    margin-right: 5px;
    position: absolute;
    left: 15px;
    top: 6px;
}

.wallpost-header .dropdown-item {
    width: 100%;
    padding: 4px 8px 4px 34px;
    margin: 0;
    font-size: 13px;
    color: var(--LightTextColor);
    line-height: normal;
    position: relative;
}

.wallpost-header .dropdown-item:hover {
    background: var(--RedColor);
    color: #fff;
}


.wall-scroll::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}

.wall-scroll::-webkit-scrollbar-track {
    border-radius: 0
}

.wall-scroll::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(227, 0, 8, .8);
    -webkit-transition: .6s;
    -moz-transition: .6s;
    -o-transition: .6s;
    transition: .6s
}

.wall-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #e30008
}

.featured-trainer-wallpost {
    margin: 0px;
    margin-bottom: 10px;
    padding: 14px;
}

.featured-trainer-wallpost .col-xl-3 {
    display: inline-block;
}

.featured-trainer-wallpost .row::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.addproduct-modal h5 {
    font-size: 18px;
}

.addproduct-modal .modal-header {
    padding-top: 12px;
    padding-bottom: 12px;
}

.addproduct-modal .modal-footer {
    padding-top: 15px;
    padding-bottom: 15px;
}

.addproduct-modal .row {
    margin-left: -8px;
    margin-right: -8px;
}

.addproduct-modal .row .col-lg-6,
.addproduct-modal .row .col-lg-12,
.addproduct-modal .row .col-lg-5 {
    padding-left: 8px;
    padding-right: 8px;
}

.upload-photo .add-productpic {
    width: 100%;
    height: 130px;
    display: inline-block;
    background-size: 80px;
    overflow: hidden;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    float: left;
}

.upload-photo .add-productpic img {
    width: 100%;
    height: 130px;
    object-fit: cover;
}

.product-display-item {
    width: 100%;
    display: block;
    background: #e4e8ed;
    position: relative;
    padding: 0px 20px 20px 20px;
    margin-top: 15px;
}



.product-display-item p {
    font-size: 15px;
    padding: 0;
    margin: 0px;
    line-height: 22px;
    text-align: left;
    color: #232323;
}

.product-display-item h6 {
    width: 100%;
    padding: 0;
    margin: 15px 0px;
    font-size: 15px;
}

.product-display-item h5 {
    width: 100%;
    padding: 20px 0px 10px 0px;
    margin: 0px 0px 20px 0px;
    position: relative;
    font-size: 14px;
    border-bottom: 1px solid #d9dfe5;
    min-height: 48px;
}

.product-display-item.active h5:before {
    background: var(--GalleryColor);
    color: #fff;
    padding: 3px 15px;
    margin: 0;
    font-size: 13px;
    border-radius: 3px;
    content: 'Active';
    position: absolute;
    left: 0px;
    top: 15px;
}

.product-display-item.draft h5:before {
    background: #d9dfe5;
    color: #86878e;
    padding: 3px 15px;
    margin: 0;
    font-size: 13px;
    border-radius: 3px;
    content: 'Draft';
    position: absolute;
    left: 0px;
    top: 15px;
}

.product-display-item.pending h5:before {
    background: #d9dfe5;
    color: #86878e;
    padding: 3px 15px;
    margin: 0;
    font-size: 13px;
    border-radius: 3px;
    content: 'Pending';
    position: absolute;
    left: 0px;
    top: 15px;
}



.product-display-item .dropdown {
    position: absolute;
    top: 15px;
    right: 0px;
    z-index: 100;
    color: #232323;
}

.product-display-item .dropdown .dropdown-icon {
    color: #232323;
    font-size: 18px;
    padding: 10px 10px;
}

.product-display-item .dropdown .dropdown-menu a {
    padding: 5px 15px;
    margin: 0;
    font-size: 14px;
}

.product-display-item .dropdown .dropdown-menu a:hover {
    background: var(--GalleryColor);
    color: #fff;
}

.product-display-item .addnew-btn {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.product-display-item h4 {
    width: 100%;
    padding: 0;
    margin: 0px 0px 8px 0px;
    font-size: 18px;
}

.product-display-image {
    width: 156px;
    height: 158px;
    position: absolute;
    left: 0px;
    top: 0px;
    background: var(--ContactColor);
    /* background: url(../img/add-product-placeholder.jpg) no-repeat center; */
    background-size: 80px;
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);

}

.product-display-image img {
    width: 156px;
    height: 158px;
    object-fit: cover;
}

.product-display-item .product-content-wrap {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    padding-left: 200px;
}


.sharetaglah-wrap .row {
    margin-left: -8px;
    margin-right: -8px;
}

.sharetaglah-wrap .row .col-lg-4,
.sharetaglah-wrap .row .col-12 {
    padding-left: 8px;
    padding-right: 8px;
}


.sharetaglah-wrap .row .col-lg-4 button {
    width: 100%;
    background: var(--PrimaryColor);
    margin-top: 10px;
}

.sharetaglah-wrap .copylink-con {
    position: relative;
    padding-right: 60px;
    margin-bottom: 15px;
}

.sharetaglah-wrap .copylink-con .input-item {
    margin-top: 0px;
}

.sharetaglah-wrap .copylink-con .input-item input {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.sharetaglah-wrap .copylink-con .addnew-btn {
    width: auto;
    background: var(--PrimaryColor);
    width: 60px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    position: absolute;
    top: 0px;
    right: 0px;
}

.sharetaglah-wrap h4 {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0px 0px 25px 0px;
    font-size: 20px;
}

.shareicon-wrap {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 45px;
}

.shareicon-wrap img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    display: inline-block;
}

.wallet-item-container {
    min-height: 130px;
}

.wallet-item-container .tag-counts .row {
    padding-right: 0px;
}

.wallet-item-container .tag-counts button:hover,
.wallet-item-container .tag-counts button:focus {
    color: var(--RedColor);
}

.wallet-cv-container {
    width: 100%;
    border: 1px solid #d9dfe5;
    margin-top: 12px;
    position: relative;
    min-height: auto;
    background: #fff;
}

.wallet-cv-container .wallet-propic {
    width: 112px;
    height: 112px;
    position: relative;
    left: 50%;

    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    padding: 0;
    margin: 15px 0px 0px 0px;
    margin-left: -56px;
    /* background: url(../img/add-product-placeholder.jpg) no-repeat center; */
}

.wallet-cv-container .wallet-propic img {
    width: 112px;
    height: 112px;
    object-fit: cover;
}

.wallet-cv-container .cv-wallet-text h6 {
    width: 100%;
    padding: 0;
    margin: 3px 0px 0px 0px;
    font-size: 12px;
    color: #4b4b4b;
    text-align: center;
}

.wallet-cv-container .cv-wallet-text h4 {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    color: #000;
    text-align: center;
}

.wallet-cv-container .cv-wallet-text {
    width: 100%;
    height: 75px;
    display: table;
}

.wallet-cv-container .cv-wallet-text span {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
}

.wallet-cv-container .walletcv-delete {
    width: 30px;
    height: 30px;
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 998;
    font-size: 14px;
    color: #9e9da3;
}

.wallet-cv-container .walletcv-qr {
    width: 30px;
    height: 30px;
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 998;
    font-size: 14px;
    color: #9e9da3;
}

.wallet-cv-container .walletcv-delete:hover,
.wallet-cv-container .walletcv-qr:hover {
    color: var(--RedColor);
}

.alertbox-style {
    position: relative;
}

.alertbox-style .close {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 12px;
    padding: 10px;
    opacity: 1;
    color: #666;
}

.alertbox-style .close:hover {
    color: var(--RedColor);
}

.alertbox-style .row {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;
}

.alertbox-style .row .col-6,
.alertbox-style .row .col-12 {
    padding-left: 0px;
    padding-right: 0px;
}

.alertbox-style .row button {
    width: 100%;
    padding: 6px 0px;
    margin: 0;
    border: none;
    outline: none;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    color: #666;

}

.alertbox-style .row .col-6:nth-child(2) button {
    border-left: 1px solid #dedcdc;
}

.alertbox-style .row button:hover {
    background: var(--RedColor);
    color: #fff;
}

.alertbox-style {
    text-align: center;
}

.alertbox-style p {
    width: 100%;
    padding: 0px 10px;
    margin: 3px 0px 10px 0px;
    font-size: 12px;
    color: #4b4b4b;
    line-height: 18px;
}

.alertbox-style h5 {
    width: 100%;
    padding: 0;
    margin: 0px 0px 5px 0px;
    font-size: 15px;
    font-weight: bold;
    color: #000;
}

.alertbox-style span {
    margin: 30px 0px 10px 0px;
}

.alertbox-style span i {
    font-size: 42px;
}

.alertbox-style.green-alert span i,
.alertbox-style.green-alert h5 {
    color: green;
}

.alertbox-style.red-alert span i,
.alertbox-style.red-alert h5 {
    color: var(--RedColor);
}


.alertbox-style.blue-alert span i,
.alertbox-style.blue-alert h5 {
    color: #0096ff;
}

.uploadaudio-list {
    width: 100%;
    padding: 0;
    margin: 0;
}

.uploadaudio-list li {
    list-style: none;
    padding: 0;
    margin: 10px 0px 0px 0px;
    position: relative;
    width: 100%;
    display: block;
    float: left;
    padding-right: 35px;
    padding-left: 20px;
}

.uploadaudio-list li button {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0px;
    right: 0px;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    background: none;
    font-size: 14px;
    color: var(--TextColor);
}

.uploadaudio-list li input {
    width: 15px;
    height: 15px;
    border: 1px solid #cfd5dd;
    background: none;
    border-radius: 50%;
    position: absolute;
    left: 0px;

    content: '';
    top: 4px;
    margin-top: 0px;
}



.uploadaudio-list li input[type='radio'] {
    margin-right: 15px;
    outline: none !important;
    background: none;
}

.uploadaudio-list li input[type='radio']:after {
    width: 16px;
    height: 16px;
    border-radius: 15px;
    top: -2px;
    left: 0px;
    position: relative;
    background-color: #ece8e6;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #bec1c5;
}

.uploadaudio-list li input[type='radio']:checked:after {
    width: 16px;
    height: 16px;
    border-radius: 15px;
    top: -2px;
    left: 0px;
    position: relative;
    background-color: var(--PrimaryColor);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid var(--PrimaryColor);
}

.uploadaudio-list li input[type='checkbox']:after {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    top: -2px;
    left: -2px;
    position: relative;
    background-color: #f0f5f9;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #024f91;
}

.uploadaudio-list li input[type='checkbox']:checked:after {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    top: -2px;
    left: -2px;
    position: relative;
    background-color: #024f91;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #024f91;
}





.uploadaudio-list li audio::-webkit-media-controls-play-button,
.uploadaudio-list li audio::-webkit-media-controls-panel {
    background-color: #ece8e6;
    color: var(--TextColor);
}

.uploadaudio-list li button:hover {
    color: var(--PrimaryColor);
}

.uploadaudio-list li audio {
    width: 100%;
    height: 24px;
}

.nav-pills .nav-link.active {
    background: var(--PrimaryColor);
}

.tab-pane input,
.tab-pane textarea,
.tab-pane select {
    box-shadow: none !important;
}

.tab-pane input:focus,
.tab-pane textarea:focus,
.tab-pane select:focus {
    border-color: var(--PrimaryColor);
}

.innerpage-subheading {
    width: 100%;
    position: relative;
    font-size: 16px;
    padding: 0;
    margin: 25px 0px 8px 0px;
}

.innerpage-subheading button {
    float: right;
    padding: 0;
    margin: 2px 0px 0px 0px;
    color: var(--headingColor);
    background: none;
    border: none;
    outline: none;
    font-size: 14px;
    cursor: pointer;
    transition-duration: 0.3s;
}

.innerpage-subheading button:hover {
    color: var(--PrimaryColor);
}

#bride-details textarea,
#groom-details textarea {
    height: 140px;
}

.input-item {
    position: relative;
}

.socialmedia-links i {
    position: absolute;
    left: 14px;
    top: 11px;
    color: var(--TextColor);
    font-size: 14px;
}

.socialmedia-links input {
    padding-left: 36px;
}

.admin-image-con {
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
}

.admin-image-con img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.admin-image-con button {
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    border: none;
    outline: none;
    background: #ece8e6;
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    color: var(--headingColor);
}

.admin-image-con button:hover {
    background: var(--PrimaryColor);
    color: #fff;
}

.event-row .location-field {
    height: 108px;
}

.event-row h4 {
    text-transform: uppercase;
    padding: 0;
    margin: 8px 0px 2px 0px;
    font-size: 16px;
    font-weight: bold;
    color: var(--PrimaryColor);
}

.event-line {
    margin-top: 8px;
    padding-bottom: 0px;
}

.mobile-heading {
    display: none;
    visibility: hidden;
}

.event-row h4 label {
    float: right;
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    margin: 0px 0px 0px 0px;
    color: var(--TextColor);
    text-transform: none;
    font-weight: 500;
}

.event-row h4 label input {
    margin-right: 4px;
    position: relative;
    top: 2px;
}

.invitation-image img {
    height: auto !important;
}

.greetings-item {
    width: 100%;
    position: relative;
    border-radius: 5px;
    padding: 30px 20px 30px 20px;
    background: #ece8e6;
    margin-bottom: 6px;
    display: inline-block;
    overflow: hidden;
}

.greetings-item button {
    width: 30px;
    height: 30px;
    background: none;
    outline: none;
    padding: 0;
    margin: 0;
    position: absolute;
    border: none;
    top: 0px;
    right: 0px;
    z-index: 900;
    font-size: 12px;
    color: var(--headingColor);
}

.greetings-item button:hover,
.greetings-item button:focus {
    color: var(--PrimaryColor);
}

.greetings-item p {
    font-size: 15px;
    padding: 0;
    margin: 0;
    text-align: left;
    line-height: 25px;
    color: var(--LightTextColor);
}

.greetings-item h5 {
    width: 100%;
    padding: 0;
    margin: 10px 0px 3px 0px;
    font-size: 15px;
    font-weight: 500;
}

.greetings-item h6 {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: normal;
    color: var(--LightTextColor);
}

.warm-regards-wrapper span {
    margin-bottom: 0px !important;
}

.warm-regards-wrapper .upload-photo h5 {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0px 0px 0px 0px;
    font-size: 15px;
}

.warm-regards-wrapper .upload-photo h6 {
    width: 100%;
    text-align: center;
    font-weight: normal;
    padding: 0;
    margin: 4px 0px 0px 0px;
    font-size: 13px;
}

.warmregards-container {
    width: 100%;
    height: 60px;
    display: table;
}

.warmregards-container span {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
}

.warm-regards-wrapper .upload-photo {
    margin-top: 15px;
}

.customize-item .form-range {
    width: 100%;
}

.sliders {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    outline: none;
    margin: 0px 0;
}

.slider-container {
    position: relative;
    padding-right: 45px;
}

.slider-container .slider-value {
    position: absolute;
    top: 1px;
    right: 0px;
    font-size: 13px;
    font-weight: bold;
}

.colour-seperate-line:before {
    display: none;
    visibility: hidden;
}

.right-sidebar {
    width: 330px;
    height: 310px;
    display: table;
    position: fixed;
    top: 60px;
    right: -230px;
    background: #d3d6da;
    z-index: 1025;
    transition-duration: 0.3s;
    border-radius: 10px;
    padding-bottom: 66px;
    overflow: hidden;
}

.right-sidebar.videsidebar {
    right: 10px;
}

.right-sidebar ul {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    padding: 0;
    margin: 0;
}

.right-sidebar ul li {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
    display: inline-block;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
}

.right-sidebar ul li:last-child {
    border-bottom: none;
}

.right-sidebar ul li button {
    width: 100%;
    padding: 14px 0px;
    margin: 0;
    background: none;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 400;
    line-height: normal;
    transition-duration: 0.3s;
    font-size: 14px;
}

.right-sidebar ul li button:hover {
    background: rgba(0, 0, 0, 0.1);
}

.right-sidebar h6 {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    color: #f2c8d2;
    font-weight: normal;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    background: #dd516a;
    padding: 15px 0px;
    margin: 0;
}

.right-sidebar h6 b {
    font-size: 14px;
    color: #fff;
}

.notification-bell {
    position: relative;
}

.notification-bell.active:before {
    width: 5px;
    height: 5px;
    background: var(--RedColor);
    border-radius: 50%;
    top: 15px;
    right: 15px;
    content: '';
    position: absolute;
    -webkit-animation: puls 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: puls 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: puls 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: puls 1.25s infinite cubic-bezier(0.66, 0, 0, 1);

    box-shadow: 0 0 0 0 rgba(236, 94, 119, 0.5);

}

@-webkit-keyframes puls {
    to {
        box-shadow: 0 0 0 8px rgba(25, 153, 204, 0);
    }
}

@-moz-keyframes puls {
    to {
        box-shadow: 0 0 0 8px rgba(25, 153, 204, 0);
    }
}

@-ms-keyframes puls {
    to {
        box-shadow: 0 0 0 8px rgba(25, 153, 204, 0);
    }
}

@keyframes puls {
    to {
        box-shadow: 0 0 0 8px rgba(25, 153, 204, 0);
    }
}


.nogreetings-received {
    width: 100%;
    text-align: center;
    display: table;
    height: calc(100vh - 220px);
}

.nogreetings-received h6 {
    display: table-cell;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    font-size: 15px;
    opacity: 0.5;
}

.select-event-item {
    width: 100%;
    height: auto;
    background: #ece8e6;
    color: var(--headingColor);
    border-radius: 3px;
    margin-top: 8px;
    float: left;
    margin-bottom: 8px;
    overflow: hidden;
    padding: 25px 0px 25px 0px;
    border: none;
    outline: none;
    min-height: 186px;
}

.select-event-item:hover h5 {
    color: var(--RedColor);
}

.select-event-item h5 {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 3px 0px 4px 0px;
    font-size: 15px;
    transition-duration: 0.3s;
    color: var(--headingColor);
}

.select-event-item h6 {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    opacity: 0.8;
    color: var(--headingColor);
}

.select-event-item span {
    width: 90px;
    height: 90px;
    background: #fff;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
}

.select-event-item span img {
    width: 90px;
    height: 90px;
    object-fit: cover;
}

.select-event-row {
    margin-left: -8px;
    margin-right: -8px;
}

.select-event-row .col-xl-2 {
    padding-left: 8px;
    padding-right: 8px;
}

.select-event-item.disabled {
    cursor: default;
    opacity: 0.3;
}

.select-event-item.disabled:hover h5 {
    color: var(--headingColor);
}

.select-event-item.active {
    background: var(--RedColor);
    position: relative;
}

.select-event-item.active h5 {
    color: #fff;
}

.select-event-item.active:before {
    position: absolute;
    left: 10px;
    top: 10px;
    content: 'Active';
    background: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: normal;
    color: var(--RedColor);
    font-size: 11px;
    padding: 4px 10px;
    border-radius: 12px;
    border-top-left-radius: 0px;
}

.addproduct-modal textarea {
    border: 1px solid #ced4da;
    transition-duration: 0.3s;
}

.addproduct-modal textarea:focus {
    border: 1px solid var(--RedColor);
    box-shadow: none !important;
}

.preview-wrapper {
    /* background: url(../img/laptopmockup.png) no-repeat center #ece8e6 !important; */
}


.laptop {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(.6);
    transform: scale(.6);
    position: absolute;
    width: 1300px;
    height: 800px;
    left: 50%;
    top: 50%;
    margin-left: -390px;
    margin-top: -250px;
}

.laptop .content {
    width: 1300px;
    height: 800px;
    overflow: hidden;
    border: none;
}

.appreance-toggle {
    width: 50px;
    height: 50px;
    background: #fff;
    color: var(--TextColor);
    position: absolute;
    left: 50%;
    margin-left: -25px;
    bottom: 20px;
    border: none;
    outline: none;
    padding: 0;
    text-align: center;
    border-radius: 50%;
    transition-duration: 0.3s;
    font-size: 20px;
    z-index: 990;

    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
}

.appreance-toggle:hover {
    background: var(--PrimaryColor);
    color: #fff;
}

.smartphone {
    display: none;
    visibility: hidden;
}

.mobile-preview .smartphone {
    display: block;
    visibility: visible;
}

.mobile-preview {
    /* background: url(../img/mobile-mockup.png) no-repeat center #ece8e6 !important; */
} 

.mobile-preview .laptop {
    display: none;
    visibility: hidden;
}

.smartphone {
    position: relative;
    width: 360px;
    height: 600px;
    margin: auto;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;

    left: 50%;
    top: 50%;
    margin-left: -144px;
    margin-top: -240px;

    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);

}


/* The screen (or content) of the device */
.smartphone .content {
    width: 360px;
    height: 600px;
}

.cropimage-modal .modal-footer {
    width: 100%;
    padding: 10px 0px;
}

.cropimage-modal .modal-footer .row {
    width: 100%;
    margin: 0px;
}

.cropimage-modal .modal-footer .row .col-6:first-child {
    padding-right: 3px;
}

.cropimage-modal .modal-footer .row .col-6:last-child {
    padding-left: 3px;
}

.cancel-btn {
    background: #ece8e6;
    color: var(--TextColor);
}

.cropimage-modal {
    max-width: 800px;
}





@media screen and (max-width: 1700px) {
    .preview-wrapper {
        background-size: 780px auto !important;
    }

    .laptop {
        margin-left: -325px;
        margin-top: -205px;
        -webkit-transform: scale(.5);
        transform: scale(.5);
    }

    .mobile-preview {
        /* background: url(../img/mobile-mockup.png) no-repeat center #ece8e6 !important; */
        background-size: 300px auto !important;
    }

    .smartphone {
        left: 50%;
        top: 50%;
        margin-left: -135px;
        margin-top: -225px;
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

}

@media screen and (max-width: 1400px) {
    .preview-wrapper {
        background-size: 650px auto !important;
    }

    .laptop {
        margin-left: -273px;
        margin-top: -175px;
        -webkit-transform: scale(0.42);
        transform: scale(0.42);
    }

    .mobile-preview {
        /* background: url(../img/mobile-mockup.png) no-repeat center #ece8e6 !important; */
        background-size: 270px auto !important;
    }

    .smartphone {
        left: 50%;
        top: 50%;
        margin-left: -117px;
        margin-top: -195px;
        -webkit-transform: scale(0.65);
        transform: scale(0.65);
    }
}

@media screen and (max-width: 1200px) {
    .preview-wrapper {
        background-size: 550px auto !important;
    }

    .laptop {
        margin-left: -228px;
        margin-top: -150px;
        -webkit-transform: scale(0.35);
        transform: scale(0.35);
    }

    .mobile-preview {
        /* background: url(../img/mobile-mockup.png) no-repeat center #ece8e6 !important; */
        background-size: 220px auto !important;
    }

    .smartphone {
        left: 50%;
        top: 50%;
        margin-left: -99px;
        margin-top: -165px;
        -webkit-transform: scale(0.55);
        transform: scale(0.55);
    }
}

@media screen and (max-width: 1024px) {
    .preview-wrapper {
        background-size: 450px auto !important;
    }

    .laptop {
        margin-left: -188px;
        margin-top: -120px;
        -webkit-transform: scale(0.29);
        transform: scale(0.29);
    }

    .mobile-preview {
        /* background: url(../img/mobile-mockup.png) no-repeat center #ece8e6 !important; */
        background-size: 220px auto !important;
    }

    .smartphone {
        left: 50%;
        top: 50%;
        margin-left: -99px;
        margin-top: -165px;
        -webkit-transform: scale(0.55);
        transform: scale(0.55);
    }
}

@media screen and (max-width: 768px) {
    .preview-wrapper {
        background-size: 380px auto !important;
        height: 300px !important;
    }

    .laptop {
        margin-left: -156px;
        margin-top: -102px;
        -webkit-transform: scale(0.24);
        transform: scale(0.24);
    }

    .mobile-preview {
        /* background: url(../img/mobile-mockup.png) no-repeat center #ece8e6 !important; */
        background-size: 220px auto !important;
        height: 480px !important;
    }

    .smartphone {
        left: 50%;
        top: 50%;
        margin-left: -99px;
        margin-top: -165px;
        -webkit-transform: scale(0.55);
        transform: scale(0.55);
    }
}

@media screen and (max-width: 520px) {
    .preview-wrapper {
        background-size: 330px auto !important;
        height: 245px !important;
    }

    .laptop {
        margin-left: -136px;
        margin-top: -89px;
        -webkit-transform: scale(0.21);
        transform: scale(0.21);
    }

    .mobile-preview {
        /* background: url(../img/mobile-mockup.png) no-repeat center #ece8e6 !important; */
        background-size: 220px auto !important;
        height: 480px !important;
    }

    .smartphone {
        left: 50%;
        top: 50%;
        margin-left: -99px;
        margin-top: -165px;
        -webkit-transform: scale(0.55);
        transform: scale(0.55);
    }
}

.close {
    border: none;
    outline: none;
    background: none;
}



@media screen and (max-width: 1024px) {

    .sidebar-active,
    .sidebar-active .questionnaire-footer {
        padding-left: 0px;
    }


    .questionnaire-footer .col-lg-6 h6 span {
        display: inline-block;
        visibility: visible;
        width: auto;
    }

    .sidebar-menu {
        -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
        padding-right: 10px;

    }

    .meetings-rowdiv,
    .create-three-btns,
    .featured-post-wrapper .row {
        margin-left: -5px;
        margin-right: -5px;
    }

    .meetings-rowdiv .col-xl-4,
    .create-three-btns .col-lg-4,
    .featured-post-wrapper .row .col-lg-4 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .sidebar-active .sidebar-menu {
        left: -240px;
    }

    .sidebar-menu {
        left: 0px;
    }

    .sidebar-padding {
        padding-right: 0px;
    }

    .sidebar-padding .rightsidebar-wrapper,
    .sidebar-padding .chat-typearea {
        right: -415px !important;
    }

    .rightsidebar-wrapper,
    .chat-typearea {
        right: 0px !important;
    }

    .sidebar-padding .rightsidebar-wrapper .rightsidebar-button {
        right: -415px;
    }

    .rightsidebar-wrapper .rightsidebar-button {
        right: 0px;
    }

    .skills-listing {
        margin-left: -5px;
        margin-right: -5px;
    }

    .skills-listing .col-xl-3 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .admin-image-con img {
        height: 120px;
    }

}

@media screen and (max-width: 980px) {
    .createtag-btn h4 {
        font-size: 15px;
    }

    .createtag-btn h6 {
        font-size: 13px;
    }

    .featured-resume-wapper .row,
    .statitics-wrapper .row,
    .suggested-company-wrap .row {
        overflow-y: auto;
        white-space: nowrap;
        display: block;
        margin-left: -5px;
        margin-right: -5px;
    }

    .featured-resume-wapper .row .col-xl-2,
    .statitics-wrapper .col-xl-2,
    .suggested-company-wrap .col-xl-3 {
        display: inline-block;
        white-space: normal;
        padding-left: 5px;
        padding-right: 5px;
    }

    .interview-request-wrap {
        margin-top: 20px;
    }

    .preview-wrapper {
        margin-top: 20px;
        height: 40vh;
    }

    .desktop-mobile-btns {
        bottom: auto;
        top: 15px;
    }

    .family-item-wrap {
        margin-left: -5px;
        margin-right: -5px;
    }

    .family-item-wrap .col-xl-4 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .family-item-wrap .family-item {
        margin-top: 10px;
        padding: 80px 0px 40px 0px;
        text-align: center;
    }

    .family-item-wrap .family-item .family-image {
        left: 50%;
        margin-left: -33px;
    }

    .family-item .family-buttons {
        width: 100%;
        top: auto;
        bottom: 0px;
        z-index: 500;
        border-top: 1px solid #d9dfe5;
    }

    .family-item .family-buttons button {
        border: none;
        height: 30px;
        width: 50%;
        line-height: 30px;
        position: relative;
    }

    .family-item .family-buttons button:last-child {
        border-right: 1px solid #d9dfe5;
    }

    .family-item .family-buttons button span {
        display: inline-block;
        visibility: visible;
        margin-left: 5px;
    }

    .customize-content {
        max-height: auto !important;
        max-height: 100%;
        height: auto;
        overflow: visible !important;
    }

    .questionnare-scroll {
        height: auto;
        overflow: visible;
        padding-right: 0px;
    }

    .image-questions img {
        margin-bottom: 20px;
    }

    .answered-questions {
        margin-top: 25px;
        margin-bottom: 75px;
    }

    .questions-status {
        overflow-y: auto;
        white-space: nowrap;
        display: block;
    }

    .questions-status li {
        display: inline-block;
    }

    .mobile-heading {
        display: block;
        visibility: visible;
    }

    .event-row .col-xl-10 h4 {
        display: none;
        visibility: hidden;
    }

    .event-line {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 520px) {
    .search-btn {
        display: none !important;
        visibility: hidden !important;
    }

    .header-wrapper .col-5 button {
        min-width: auto;
    }

    .header-wrapper .col-7 button {
        min-width: 46px;
    }

    .createtag-btn h4 {
        font-size: 12px;
    }

    .createtag-btn h6 {
        font-size: 15px;
    }

    .createtag-btn span {
        width: 50px;
        height: 41px;
        background-size: 100% !important;
    }

    .createtag-btn:hover span,
    .createtag-btn:focus span {
        background-position-y: -41px !important;
    }

    .createtag-btn {
        height: 140px;
    }

    .mytag-wrapper {
        margin-top: 25px;
    }

    .meetings-rowdiv,
    .features-postrow {
        overflow-y: auto;
        white-space: nowrap;
        display: block;
    }

    .meetings-rowdiv .col-10,
    .features-postrow .col-10 {
        display: inline-block;
        white-space: normal;
    }

    .suggested-company-wrap .col-xl-3 span img {
        max-height: 60px;
        min-height: 60px;
        max-width: 100%;
        display: inline-block;
        object-fit: cover;
    }

    .video-holder .video-buttons li button {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 13px;
    }

    .video-holder .video-buttons {
        bottom: 15px;
    }

    .video-holder .video-buttons li {
        padding: 0px 5px;
    }

    .video-navbtns {
        display: none;
        visibility: hidden;
    }

    .video-scroll-wrap {
        padding-left: 0px;
        padding-right: 0px;
    }

    .video-scroll-wrap .smallvideo-holder ul li button {
        width: 25px;
        height: 10px;
    }

    .video-scroll-wrap .smallvideo-holder ul li button i {
        font-size: 12px;
    }

    .video-scroll-wrap .smallvideo-holder ul {
        right: 5px;
    }

    .video-scroll-wrap .smallvideo-holder h6 {
        font-size: 13px;
        padding: 8px;
        bottom: 3px;
    }

    .big-video-placeholder .selfvideo-holder {
        width: 75px;
        height: 75px;
        top: 10px;
        right: 10px;
    }

    .big-video-placeholder .logo-holder {
        left: 10px;
        top: 10px;
    }

    .big-video-placeholder .logo-holder img {
        height: 45px;
    }

    .header-wrapper img {
        height: 35px;
    }

    .sidebar-padding .rightsidebar-wrapper,
    .sidebar-padding .chat-typearea {
        right: -100% !important;
    }

    .rightsidebar-wrapper,
    .rightsidebar-wrapper .tab-height .chat-typearea {
        width: 100%;
    }

    .wallfooter-wrap {
        display: block;
        visibility: visible;
    }

    .rightsidebar-wrapper .sidebar-buttons {
        display: none;
        visibility: hidden;
    }

    .big-video-placeholder {
        padding-top: 60px;
    }

    .video-scroll-wrap .row .col-lg-3 {
        padding-bottom: 50px;
    }

    .rightsidebar-wrapper .tab-height .chat-typearea {
        bottom: 40px;
    }

    .chatscroll {
        padding-bottom: 240px;
    }

    .product-div-wrapper {
        padding-bottom: 130px;
    }

    .sidebar-padding .rightsidebar-wrapper .rightsidebar-button {
        right: -100%;
    }

    .rightsidebar-wrapper .rightsidebar-button {
        right: 0px;
        width: 100%;
    }

    .rightsidebar-wrapper .rightsidebar-button {
        bottom: 45px;
    }

    .orderconfirm-wrapper {
        padding-bottom: 245px;
    }

    .cart-icon-div {
        position: absolute !important;
        left: 0px;
        top: 0px;
    }

    .confirm-order-scroll {
        padding-right: 5px;
    }

    .videothumbnail-wrap .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .videothumbnail-wrap .row .col-lg-3 {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .videothumbnail-wrap {
        padding-bottom: 40px !important;
        margin-top: 45px;
    }

    .cv-bodypadding,
    .cvfooter-wrap {
        padding-left: 0px;
    }

    .savedate-sidebar {
        width: 100%;
        height: auto;
    }

    .savedate-sidebar .nav-link {
        width: auto;
        display: inline-block;
        white-space: normal;
        padding: 6px 12px;
        background: #ece8e6;
        text-align: center;
    }

    .savedate-sidebar .nav {
        overflow-y: auto;
        white-space: nowrap;
        display: block;
        margin: 0px;
        padding-bottom: 10px;
    }

    .savedate-sidebar .nav-link i {
        left: auto;
        right: auto;
        top: auto;
        position: relative;
    }

    .savedate-sidebar .nav-link h6 {
        font-size: 13px;
        margin-top: 2px;
    }

    .savedate-sidebar .nav-link:after {
        top: auto;
        margin-top: 0px;
        right: 50%;
        bottom: -6px;
        z-index: -1;
        margin-right: -8px;
    }

    .savedate-sidebar {
        background: #f6f7fa;
    }

    .savedate-sidebar::before {
        width: 100%;
        height: 68px;
        content: '';
        position: absolute;
        left: 0px;
        top: 50px;
        background: #ece8e6;
        z-index: 0;
    }


    .innerpage-heading {
        margin-top: 60px;
        margin-bottom: 10px;
    }

    .innerpage-heading h2 {
        font-size: 16px;
        margin-top: 4px;
    }

    .cvfooter-wrap .save-btn {
        margin-right: 10px;
    }

    .prev-btn {
        margin: 0px 0px 0px 10px;
    }

    .cvfooter-wrap .skip-btn {
        padding: 0px;
    }

    .input-item label,
    .cvfooter-wrap .save-btn,
    .cvfooter-wrap .skip-btn,
    .prev-btn {
        font-size: 13px;
    }

    .appearance-item {
        margin: 10px 0px;
    }

    .desktop-mobile-btns li a {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
    }

    .desktop-mobile-btns li {
        padding: 0px 5px;
    }

    .accod-style .card .education-buttons {
        position: relative;
        width: 100%;
        border-top: 1px solid #d9dfe5;
    }

    .accod-style .card .education-buttons button {
        padding: 6px 0px;
        margin: 0;
        width: 50%;
        text-align: center;
        height: auto;
        line-height: normal;
    }

    .accod-style .card .education-buttons button span {
        visibility: visible;
        display: inline-block;
        margin-left: 5px;
    }

    .accod-style .card .education-buttons button:first-child {
        border-left: 1px solid #d9dfe5;
    }

    .accod-style .card .btn-link {
        padding-right: 15px;
    }

    .accod-style .card .downicon {
        right: 15px;
    }

    #gallery .gallery-item img {
        height: 100px;
    }

    .questionnaire-item h6 {
        font-size: 16px;
    }

    .questionnaire-footer .previous-btn {
        margin-left: 5px;
        font-size: 13px;
        padding: 0px 0px;
    }

    .questionnaire-footer .next-btn {
        margin-right: 5px;
        font-size: 13px;
        padding: 0px 0px;
    }

    .questionnaire-footer .col-lg-6 h6 {
        font-size: 13px;
    }

    .answered-questions h4 {
        font-size: 18px;
    }

    .questionnare-heading {
        margin-top: 0px;
    }

    .field-items .input-list li {
        margin-bottom: 5px;
    }

    .field-items .input-list li:last-child {
        margin-bottom: 0px;
    }

    .submitbtn {
        width: 100%;
    }

    .dashboard-header-menu,
    .goto-groupbtn {
        display: none;
        visibility: hidden;
    }

    .wall-scroll {
        height: auto;
        margin-bottom: 45px;
    }

    .fourimg-style .col-lg-8 .wallimage {
        height: 31vh;
    }

    .fourimg-style .col-lg-8 .wallimage img {
        height: calc(31vh - 6px);
    }

    .fourimg-style .col-lg-4 .wallimage {
        height: 10.33vh;
    }

    .fourimg-style .col-lg-4 .wallimage img {
        height: calc(10.33vh - 6px);
    }

    .oneimg-style .col-xl-12 img {
        height: 35vh;
    }

    .twoimg-style .col-xl-6 img {
        height: 20vh;
    }

    .threeimg-style .col-xl-4 img {
        height: calc(13vh - 3px);
    }

    .threeimg-style .col-xl-8 img {
        height: 26vh;
    }

    .product-display-item .product-content-wrap {
        padding-left: 0px;
    }

    .savedate-sidebar .nav-link h6 {
        padding-left: 0px;
        font-size: 12px;
        margin-top: 24px;
    }

    .savedate-sidebar .nav-link span {
        left: 50%;
        margin-left: -18px;
    }

    .admin-image-con img {
        height: auto;
    }

    .select-event-item.active:before {
        font-size: 10px;
    }

    .appreance-toggle {
        width: 30px;
        height: 30px;
        margin-left: -15px;
        bottom: 8px;
        font-size: 14px;
    }


}

@media screen and (max-width: 360px) {}

/* Hover */
.savedate-sidebar .StateLink:hover {
  background-color:    #ceccc7; /* Update with your desired hover background color */
  /* Add other styles for hover effect */
}
/*Dealer page  */
.card {
    background: rgb(245, 232, 223);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
    border: 1px solid white;
    border-radius: 0;
  }
  
  .card span {
    font-size: 50px;
    font-weight: 800;
  }
  
  .card .small {
    font-size: 12px;
    font-weight: 500;
  }
  @media (max-width: 768px) {

    .card {
        height: 110px;
    }
  
    .card span {
        font-size: 25px;
        font-weight: 700;
    }
  
    .card .small {
        font-size: 10px;
        font-weight: 500;
    }
    
  }
  @media (max-width: 360px) {

    .card {
        height: 100px;
    }
  
    .card span {
        font-size: 20px;
        font-weight: 600;
    }
  
    .card .small {
        font-size: 10px;
        font-weight: 500;
    }
    
    
  }