.modalbody {
    overflow: auto; /* Use "auto" to display scroll bars when necessary */
    height: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 2px solid #042347;
    border-radius: 4px;
  }
  
.coupon-selector {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  h2 {
    margin-top: 0;
  }
  
  .coupon-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  button {
    width: 90px;
    height: 60px;
    font-size: 20px;
    border: none;
    border-radius: 4px;
    background-color: #ccc;
    cursor: pointer;
    font-weight: 600;
  }
  .issue-button {
    background-color: #064185; /* Set the background color */
    color: #fff; /* Set the text color */
    border: none; /* Remove the button border */
    width: 70px;
    height: 30px;
    font-size: 14px; /* Adjust the font size as needed */
    /* Add any additional styles or modifications */
  }
  .issue-button:hover {
    background-color:#1b5da9; /* Change the background color on hover */
    /* Add any additional hover styles */
  }
  
  button.selected {
    background-color: #ff9900;
  }

  .Modalcard {
    background: rgb(245, 232, 223);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    border: 1px solid white;
    border-radius: 0;
  }
  
  .Modalcard span {
    font-size: 20px;
    font-weight: 700;
  }
  
  .Modalcard .small {
    font-size: 12px;
    font-weight: 500;
  }
  @media (max-width: 768px) {

    .Modalcard {
        height: 110px;
    }
  
    .Modalcard span {
        font-size: 25px;
        font-weight: 700;
    }
  
    .Modalcard .small {
        font-size: 10px;
        font-weight: 500;
    }
    
  }
  @media (max-width: 360px) {

    .Modalcard {
        height: 100px;
    }
  
    .Modalcard span {
        font-size: 20px;
        font-weight: 600;
    }
  
    .Modalcard .small {
        font-size: 10px;
        font-weight: 500;
    }
    
    
  }