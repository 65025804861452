.progress-bar-container {
    background-color: #f9fafb;
    padding: 20px;
  }
  
  .progress-bar-container .ProgressBar {
   height: 35px;
  }
  .coupon-count img{
    height: 55%;
    width: 30%;
  }
  .Progressbar-label{
    font-weight: 600;
    font-size: 18px;
  }
  .Progressbar-span{
    font-weight: 700;
    color:red;
    font-size:20px
  }
  .coupon-count {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    .coupon-count img{
        height: 55%;
        width: 140%;
        margin-top: 7px;
      }

    
  }
  * {
    box-sizing: border-box;
  }
  
  /* Create three columns of equal width */
  .columns {
    float: left;
    width: 25%;
    padding: 2px;
  }
  
  /* Style the list */
  .price {
    list-style-type: none;
    border: 1px solid #eee;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  
  /* Add shadows on hover */
  .price:hover {
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
  }
  
  /* Pricing header */
  .price .header {
    background-color: #111;
    color: white;
    font-size: 15px;
  }
  
  /* List items */
  .price li {
    border-bottom: 1px solid #eee;
    padding: 12px;
    text-align: center;
  }
  
  /* Grey list item */
  .price .grey {
    background-color: #eee;
    font-size: 15px;
  }
  
  
  /* Change the width of the three columns to 100%
  (to stack horizontally on small screens) */
  @media only screen and (max-width: 600px) {
    .columns {
      width: 200%;
    }
    .Progressbar-label{
      font-weight: 700;
      font-size: 8.5px;
    }
    .Progressbar-span{
      font-weight: 700;
      color:red;
      font-size:13px
    }
  }

  