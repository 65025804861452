/* @font-face {
	font-family: Roboto;
	src: url(../../fonts/roboto/Roboto-Regular.ttf);
	font-weight: 400
}

@font-face {
	font-family: Roboto;
	src: url(../../fonts/roboto/Roboto-Medium.ttf);
	font-weight: 500
}

@font-face {
	font-family: Roboto;
	src: url(../../fonts/roboto/Roboto-Bold.ttf);
	font-weight: 700
} */

section {
	padding: 0px 0;
}

body {
	font-family: 'Roboto', sans-serif !important;

}

.admin-bg {}

.admin-bg:before {
	width: 440px;
	height: 100%;
	position: fixed;
	left: 0px;
	top: 0px;
	content: '';
	background: url(../Images/img/bg-left.svg) no-repeat;
	background-size: 440px auto;
	z-index: -1;
}

.admin-bg:after {
	width: 530px;
	height: 100%;
	position: fixed;
	right: 0px;
	bottom: 0px;
	content: '';
	background: url(../Images/img/bg-right.svg) bottom no-repeat;
	background-size: 530px auto;
	z-index: -1;
}button

.admin-header {
	width: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 1020;
}


.admin-header button {
	padding: 0px 15px;
	margin: 0px;
	color: #FFFFFF;
	border: none;
	outline: none;
	background: none;
	font-size: 14px;
	min-height: 35px;
	cursor: pointer;
	border-left: 1px solid #f49ead;
	float: right;
	background-color: #ec5e77;
	text-transform: uppercase;
	font-weight: 500;
}

.admin-header button:last-child {
	border-left: none;
}

.admin-header button:hover {
	background-color: #d13c55;
}

.admin-header button i {
	margin-right: 4px;
}


.footer-wrapper {
	width: 100%;
	background-color: #ece8e6;
	position: relative;
	bottom: 0px;
	text-align: left;
	min-height: 60px;
	left: 0px;
	margin-top: -60px;
}

.footer-wrapper:before {
	width: 132px;
	height: 126px;
	/* background: url(../img/footer-logo.svg) no-repeat; */
	content: '';
	position: absolute;
	left: 50%;
	margin-left: -66px;
	bottom: 20px;
	z-index: 1;

	animation: footerimg 6s infinite alternate;
	animation-direction: alternate;
	/* or: normal */
	animation-timing-function: ease-out;
}

@-webkit-keyframes footerimg {
	0% {
		transform: rotate(-12deg);
	}

	25% {}

	50% {
		transform: rotate(12deg);
	}

	75% {}

	100% {
		transform: rotate(-12deg);
	}
}

.footer-wrapper:after {
	width: 150px;
	height: 150px;
	background: #fff;
	content: '';
	position: absolute;
	left: 50%;
	margin-left: -70px;
	bottom: 10px;
	z-index: 0;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

.admin-color-buttons .col-lg-3 {
	width: 100%;
	min-height: 100vh;
	display: table;
	vertical-align: middle;
	padding-bottom: 60px;
}

.admin-color-buttons .col-lg-3 div {
	display: table-cell;
	vertical-align: middle;
}

.admin-color-buttons .col-lg-3 div button {
	width: 100%;
	border: none;
	outline: none;
	-moz-border-radius: 10px;
	border-radius: 10px;
	cursor: pointer;
	padding: 45px 0px;
}

.admin-color-buttons .col-lg-3 div button h4 {
	width: 100%;
	text-align: center;
	padding: 0px;
	margin: 0px;
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	float: left;
	color: #FFFFFF;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.admin-color-buttons .col-lg-3 div button font {
	width: 160px;
	height: 160px;
	background-color: #FFFFFF;
	float: left;
	position: relative;
	left: 50%;
	margin-left: -80px;
	margin-bottom: 20px;
	-moz-border-radius: 50%;
	border-radius: 50%;
	overflow: hidden;
}

.footer-wrapper .col-lg-6 {
	color: #421e0d;
	font-size: 14px;
	line-height: 60px;
}

.footer-wrapper .col-lg-6:last-child {
	text-align: right;
}

.footer-wrapper .col-lg-6 a {
	font-weight: 500;
	color: #421e0d;
}

.footer-wrapper .col-lg-6 a:hover {
	text-decoration: none;
}

.update-websitebtn {
	background-color: #df6c4f;
}

.eventsbtn {
	background-color: #fbad3c;
}

.order-historybtn {
	background-color: #3c948b;
}

.admin-color-buttons .col-lg-3 div button font:before {
	width: 90px;
	height: 90px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -45px 0px 0px -45px;
	content: '';
}

.admin-color-buttons .col-lg-3 div .update-websitebtn font:before {
	/* background: url(../img/websiteicon-01.svg) no-repeat; */
}

.admin-color-buttons .col-lg-3 div .update-websitebtn:hover font:before {
	/* background: url(../img/websiteicon-02.svg) no-repeat; */
}

.admin-color-buttons .col-lg-3 div .update-websitebtn:hover font {
	background-color: #df6c4f;
}

.admin-color-buttons .col-lg-3 div .update-websitebtn:hover {
	background-color: #c65538;
}


.admin-color-buttons .col-lg-3 div .eventsbtn font:before {
	/* background: url(../img/eventicon-01.svg) no-repeat; */
}

.admin-color-buttons .col-lg-3 div .eventsbtn:hover font:before {
	/* background: url(../img/eventicon-02.svg) no-repeat; */
}

.admin-color-buttons .col-lg-3 div .eventsbtn:hover font {
	background-color: #fbae45;
}

.admin-color-buttons .col-lg-3 div .eventsbtn:hover {
	background-color: #e19227;
}


.admin-color-buttons .col-lg-3 div .order-historybtn font:before {
	/* background: url(../img/historyicon-01.svg) no-repeat; */
}

.admin-color-buttons .col-lg-3 div .order-historybtn:hover font:before {
	/* background: url(../img/historyicon-02.svg) no-repeat; */
}

.admin-color-buttons .col-lg-3 div .order-historybtn:hover font {
	background-color: #3c948b;
}

.admin-color-buttons .col-lg-3 div .order-historybtn:hover {
	background-color: #297e75;
}


.sidemenubar {
	width: 300px;
	right: -310px;
	height: 100vh;
	position: fixed;
	top: 0px;
	z-index: 999;

	background: rgba(237, 95, 121, 1);
	background: -moz-linear-gradient(top, rgba(237, 95, 121, 1) 0%, rgba(237, 95, 121, 0.9) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(237, 95, 121, 1)), color-stop(100%, rgba(237, 95, 121, 0.9)));
	background: -webkit-linear-gradient(top, rgba(237, 95, 121, 1) 0%, rgba(237, 95, 121, 0.9) 100%);
	background: -o-linear-gradient(top, rgba(237, 95, 121, 1) 0%, rgba(237, 95, 121, 0.9) 100%);
	background: -ms-linear-gradient(top, rgba(237, 95, 121, 1) 0%, rgba(237, 95, 121, 0.9) 100%);
	background: linear-gradient(to bottom, rgba(237, 95, 121, 1) 0%, rgba(237, 95, 121, 0.9) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ed5f79', endColorstr='#ed5f79', GradientType=0);

	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;

	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}

.sidemenubar.addsearch {
	right: 0px;

	-moz-border-radius: 0px;
	border-radius: 0px;
}

.sidemenubar ul {
	padding: 0px;
	margin: 56px 0px 0px 0px;
	width: 100%;
}

.sidemenubar ul li {
	list-style: none;
	width: 100%;
	text-align: center;
	padding: 0px;
	margin: 0px;
	line-height: normal;
	border-bottom: 1px dashed #e495a3;
}

.sidemenubar ul li:last-child {
	border-bottom: none;
}

.sidemenubar ul li a {
	padding: 16px 0px;
	margin: 0px;
	color: #FFFFFF;
	font-weight: 500;
	font-size: 15px !important;
}

.sidemenubar ul li a:hover {
	background-color: #df4c65;
	color: #FFFFFF;
}

.sidemenubar button {
	position: absolute;
	left: 0px;
	bottom: 0px;
	width: 100%;
	border: none;
	outline: none;
	background: none;
	color: #FFFFFF;
	font-weight: 500;
	cursor: pointer;
	background-color: #db4761;
	text-transform: uppercase;
	padding: 14px 0px;
}

.admin-header .bell-icon {
	padding: 0px 20px;
	font-size: 18px;
}

.admin-header .bell-icon i {
	margin: 0px;
}


::-webkit-scrollbar {
	width: 8px;
	height: 0px;
}

::-webkit-scrollbar-track {
	border-radius: 0px;
}

::-webkit-scrollbar-thumb {
	border-radius: 0px;
	background-color: rgba(236, 94, 119, 0.7);
	-webkit-transition: all 0.6s;
	-moz-transition: all 0.6s;
	-o-transition: all 0.6s;
	transition: all 0.6s;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgba(236, 94, 119, 1);
}

.page-header-con {
	width: 100%;
	text-align: center;
	background: rgba(251, 174, 69, 0.4);
	padding-top: 10px;
	padding-bottom: 10px;
	position: fixed;
	top: 0px;
	z-index: 900;
}

.page-header-con h3 {
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	padding: 0px;
	margin: 0px;
	font-weight: 900;
	font-size: 24px;
	color: #000;
}

.admin-page-container {
	min-height: 100vh;
	padding-bottom: 150px;
	padding-top: 120px;

}

.admin-page-container .eventpage-item {
	width: 100%;
	padding: 10px;
	margin: 0px;
	background: none;
	border: none;
	outline: none;
	background: rgba(255, 255, 255, 0.6);
	border: 2px solid #fddfb8;
	cursor: pointer;
	-moz-border-radius: 5px;
	border-radius: 5px;
	position: relative;
}

.admin-page-container .eventpage-item:hover {
	border: 2px solid #eb5d76;
}

.admin-page-container .eventpage-item .couple-pic {
	width: 100%;
	height: 120px;
	/* background: url(../../wedding-01/img/savedate-logo.svg) no-repeat rgba(251, 174, 69, 0.4); */
	background-size: 90px 90px;
	background-position: 12px 13px;
	float: left;
	-moz-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden;

}

.admin-page-container .eventpage-item .couple-pic img {
	width: 100%;
	height: 120px;
	object-fit: cover;
}

.admin-page-container .eventpage-item .col-7 {
	padding-left: 0px;
	min-height: 120px;
	display: table;
	position: relative;
}

.admin-page-container .eventpage-item .col-7 h5 {
	font-size: 16px;
	font-weight: 700;
	padding: 0px;
	margin: 0px;
}

.admin-page-container .eventpage-item .col-7 h6 {
	font-size: 14px;
	font-weight: 500;
	padding: 0px;
	margin: 8px 0px 0px 0px;
	color: #333333;
}

.admin-page-container .eventpage-item .col-7 span {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
}

.events-page-content .col-lg-4 {
	position: relative;
}

.events-page-content .col-lg-4 .event-button {
	position: absolute;
	top: 0px;
	right: 20px;
	z-index: 998;
	font-size: 18px;
	border: none;
	padding: 8px;
	margin: 0px;
	background: none;
	line-height: normal;
	outline: none;
	cursor: pointer;
}

.events-page-content .col-lg-4 .event-button:hover {
	color: #eb5d76;
}

.admin-page-container .eventpage-item .col-7 font {
	width: 12px;
	height: 12px;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background-color: #c7bcb7;
	position: absolute;
	bottom: 0px;
	right: 14px;
}

.events-page-content .dropdown-menu {
	padding: 0px;
	margin: 0px;
	font-size: 14px;
	top: -10px !important;
	left: -5px;
	border: none;
	overflow: hidden;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.events-page-content .dropdown-menu button {
	outline: none;
	cursor: pointer;
	padding: 3px 10px;
}

.events-page-content .dropdown-menu button:focus,
.events-page-content .dropdown-menu button:hover {
	background: #eb5d76;
	color: #FFFFFF;
}

.events-page-content .col-lg-4 {
	margin-top: 8px;
	margin-bottom: 8px;
}

.order-history {
	background: rgba(62, 104, 148, 0.4);
}

.order-history-container .table thead {
	background: #064185;
	border: none;
}

.order-history-container .table th {
	border: none;
	padding: 8px 8px;
	color: #FFFFFF;
	font-size: 14px;
}

.order-history-container tbody tr td {
	padding: 8px 8px;
	font-size: 14px;
}

.table-striped tbody tr:nth-of-type(odd) {
	background: rgba(62, 148, 140, 0.2);
}

.order-history-container .nav-pills {
	margin-bottom: 15px;
}

.order-history-container .nav-pills a {
	padding: 8px 20px;
	font-size: 14px;
	font-weight: 500;
	color: #3e948c;
}

.order-history-container .nav-link.active {
	background: rgba(62, 148, 140, 1);
}

.events-page-content .create-newbtn {
	width: 100%;
	text-align: center;
	padding: 8px 0px;
	margin: 0px;
	border: none;
	outline: none;
	background-color: #fbaf4c;
	color: #FFFFFF;
	font-weight: 700;
	cursor: pointer;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.events-page-content .create-newbtn:hover {
	background-color: #e19330;
}

.events-page-content .create-newbtn i {
	margin-right: 8px;
}

.eventpage-item .active {
	background-color: #00CC00 !important;
}

.eventpage-item .suspend {
	background-color: #eb5d76 !important;
}

.select-image-btns {
	margin-bottom: 25px;
	margin-top: 20px;
}

.select-image-btns button {
	width: 100%;
	outline: none !important;
	box-shadow: none !important;
	font-size: 20px;
	padding: 3px 0px;
}

.crop-image-wrap h4 {
	width: 100%;
	padding: 6px 0px !important;
	margin: 20px 0px 0px 0px !important;
	text-align: center;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 700;
	background: rgba(251, 174, 69, 0.2);
}

.crop-image-wrap .col-lg-4 {
	margin-top: 0px;
	margin-bottom: 0px;
}

.crop-image-wrap img {
	width: 100%;
}

.alertbox {
	position: relative;
}

.alertbox h6 {
	width: 100%;
	padding: 0;
	margin: 10px 0px 0px 0px;
	text-align: center;
}

.alertbox p {
	width: 100%;
	text-align: center;
	padding: 0 20px;
	margin: 6px 0 15px;
	font-size: 14px;
	line-height: 20px;
	color: #535252;
}

.alertbox span {
	width: 70px;
	height: 70px;
	background: #efefef;
	display: block;
	position: relative;
	left: 50%;
	margin-left: -35px;
	border-radius: 50%;
	color: #ec5e77;
	margin-top: 15px;
	font-size: 34px;
	padding-top: 12px;
	text-align: center;
}

.alertbox span.success {
	background: #05b411;
	color: #fff;
}

.alertbox .close {
	position: absolute;
	top: 8px;
	right: 8px;
	opacity: 1;
	font-size: 14px;
	outline: none;
}

.alertbox .okbtn {
	padding: 8px 0;
	margin: 0;
	border: none;
	width: auto;
	font-size: 14px;
	outline: none;
	font-weight: 500;
	transition: all .4s;
	cursor: pointer;
}

.alertbox .okbtn:hover {
	background-color: #ec5e77 !important;
	color: #fff;
}

.alertbox .okbtn {
	width: 100%;
}

.alertbox .row .col-6:first-child {
	padding-right: 0px;
}

.alertbox .row .col-6:last-child {
	padding-left: 0px;
}

.alertbox .row .col-6:last-child .okbtn {
	background-color: #e2e2e2;
}

/*
-------------------------------------------------------------------------*/

.order-history-container td button {
	background: none;
	border: 2px solid #064185;
	color: #064185;
	padding: 2px 10px;
	margin: 0px;
	font-weight: 500;
	font-size: 12px;
	cursor: pointer;
	margin-left: 5px;
	outline: none !important;
}

.order-history-container td button:hover {
	background: #064185;
	color: #fff;

}

.order-history-container tr td:nth-child(6),
.order-history-container tr th:nth-child(6) {
	width: 225px !important;
	min-width: 225px;
}

.order-history-container tr td:nth-child(1),
.order-history-container tr th:nth-child(1) {
	width: 225px !important;
	min-width: 225px;
}

.order-history-container tr td:nth-child(2),
.order-history-container tr th:nth-child(2) {
	width: 120px !important;
	min-width: 120px;
}

.order-history-container tr td:nth-child(3),
.order-history-container tr th:nth-child(3) {
	width: 260px !important;
	min-width: 260px;
}

.order-history-container tr td:nth-child(4),
.order-history-container tr th:nth-child(4) {
	width: 100px !important;
	min-width: 100px;
}

.search-box input {
	box-shadow: none !important;
	height: 36px;
	border-radius: 0px;
	font-size: 15px;
}

.search-box input:focus {
	border-color: #3e948c;
}

.search-box {
	width: 100%;
	position: relative;
}


.search-box button {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 36px;
	height: 36px;
	border: none !important;
	outline: none !important;
	color: #fff;
	background: #3e948c;
	z-index: 10;
	cursor: pointer;
}

.filter-box-wrap h5 {
	width: 100%;
	padding: 0;
	margin: 0;
	line-height: 26px;
	font-size: 15px;
}

.filter-box-wrap {
	width: 100%;
	position: fixed;
	left: 0px;
	top: 49px;
	padding: 6px 0px;
	z-index: 900;
	background: rgba(255, 255, 255, 0.9);
	-webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

.order-history-container .pagination a {
	color: #000;
	box-shadow: none !important;
}


@media screen and (max-width: 1024px) {
	.admin-page-container .eventpage-item .couple-pic {
		background-size: 80px 80px;
		background-position: 8px 18px;
	}
}

@media screen and (max-width: 980px) {}

@media screen and (max-width: 820px) {
	.admin-color-buttons .col-lg-3 {
		min-height: inherit;
		display: block;
	}

	.admin-color-buttons {
		margin-top: 80px;
	}

	.footer-wrapper {
		position: relative;
		margin-top: 45px;
	}

	.admin-color-buttons .col-lg-3 div button font {
		width: 100px;
		height: 100px;
		margin-left: -50px;
	}

	.admin-color-buttons .col-lg-3 div {
		width: 100%;
		float: left;
	}

	.admin-color-buttons .col-lg-3 div button {
		padding: 25px 0px;
		min-height: 218px;
	}

	.admin-color-buttons .col-lg-3 div button font:before {
		width: 60px;
		height: 60px;
		margin: -30px 0px 0px -30px;
	}

	.admin-color-buttons .col-lg-3 div button h4 {
		font-size: 18px;
		padding: 0px 10px;
	}

	.admin-page-container {
		min-height: inherit;
		padding-bottom: 50px;
	}

	.page-header-con {
		padding-bottom: 6px;
		padding-top: 00px;
	}

	.page-header-con h3 {
		font-size: 18px !important;
		text-align: left;
		padding: 9px 0px 0px 12px;
	}

	.order-history-container .nav-pills {
		margin-top: 15px;
	}

	.events-page-content .create-newbtn {
		margin-top: 15px;
	}
}

@media screen and (max-width: 480px) {
	.admin-color-buttons .col-lg-3 {
		padding-bottom: 20px;
	}

	.admin-bg:before,
	.admin-bg:after {
		display: none;
		visibility: hidden;
	}

	.footer-wrapper .col-lg-6 {
		line-height: normal;
		text-align: center !important;
		min-height: 24px;
	}

	.footer-wrapper {
		padding-top: 45px;
		margin-top: 100px;
		padding-bottom: 12px;
	}

	.footer-wrapper:before {
		bottom: 76px;
	}

	.footer-wrapper:after {
		bottom: 66px;
	}

	.admin-color-buttons .col-lg-3 div button {
		padding: 20px 0px;
		min-height: inherit;
	}

	.admin-color-buttons .col-lg-3 div button font {
		margin-bottom: 10px;
	}



	.admin-page-container .eventpage-item .couple-pic,
	.admin-page-container .eventpage-item .col-7 {
		height: 100px;
		min-height: 100px;
	}

	.events-page-content .col-lg-4 {
		margin-top: 10px;
		margin-bottom: 0px;
	}

	.admin-page-container .eventpage-item .couple-pic {
		background-position: 16px 10px;
	}

	.page-header-con h3 {
		font-size: 26px;
		margin: 5px 0px;
	}

	.select-image-btns {
		margin-bottom: 0px;
	}
}

@media screen and (max-width: 375px) {}

@media screen and (max-width: 240px) {}
