.active-button {
    width: 100%;
    background: #064185;
    color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transform: translateY(0px);
    transition: transform 0.3s ease, background 0.3s ease;
  }
  
  .inactive-button {
    width: 100%;
    background: rgba(62, 104, 148, 0.4);
    color: initial;
    border: none;
    border-radius: 5px;
    box-shadow: none;
    transform: translateY(2px);
    transition: transform 0.3s ease, background 0.3s ease;
  }
  
  .active-button:hover {
    transform: translateY(-2px);
    background: #064185;
  }
  
  .inactive-button:hover {
    transform: translateY(0px);
    background: rgba(22, 70, 121, 0.4);
  }
 