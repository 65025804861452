.districtbody{
    width: 100%;
}
.district-coupon-selector{
    overflow: auto; /* Use "auto" to display scroll bars when necessary */
    max-height: 300px;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    background-color: white;
}
.district-coupon-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    border-radius: 4px;
}
.issue-button {
    background-color: #064185; /* Set the background color */
    color: #fff; /* Set the text color */
    border: none; /* Remove the button border */
    width: 70px;
    height: 30px;
    font-size: 14px; /* Adjust the font size as needed */
    /* Add any additional styles or modifications */
  }

  .CouponFooter {
    display: flex;
    flex-direction: row;
    padding: 20px;
  }
  
  .dropdown-container {
    flex: 1;
  }
  
  .button-container {
    margin-left: 10px; /* Add some spacing between the dropdown and the button */
  }
  
  @media (max-width: 600px) {
    .container {
      flex-direction: column; /* Switch to a vertical layout on small screens */
    }
  
    .button-container {
      margin-left: 0; /* Remove the spacing between the dropdown and the button */
      margin-top: 10px; /* Add some spacing between the dropdown and the button */
    }
  }
  .requestcoupon{
    margin-top: 40px;
    text-align: center;
    font-family: 'Roboto', sans-serif !important;
    color: rgb(0, 0, 0);
    background-color: rgb(245, 232, 223);
    /* background-color: rgba(62, 104, 148, 0.4); */
   padding: 20px;
  }
  .subscription{
    margin-top: 25px;
    text-align: center;
    font-family: 'Roboto', sans-serif !important;
    color: rgb(0, 0, 0);
    background-color: rgb(245, 232, 223);
   padding: 20px;
  }