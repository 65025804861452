body {
    font-family: 'Roboto', sans-serif !important;

    --PrimaryColor: #e69221;
    --headingColor: #333333;
    --textcolor: #999999;

}


button {
    outline: none !important;
    transition-duration: 0.3s;
    font-family: 'Roboto', sans-serif !important;
}

p {
    font-size: 16px;
    line-height: 28px;
    color: var(--textcolor);
    font-family: 'Roboto', sans-serif !important;
}

a {
    outline: none !important;
    transition-duration: 0.3s;
    font-family: 'Roboto', sans-serif !important;
}


.container {
    max-width: 1240px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--headingColor);
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif !important;
}

.login-page {
    position: relative;
}

.login-page::before {
    width: 290px;
    height: 314px;
    position: absolute;
    right: 0px;
    top: 0px;
    background: url(../Images/LoginImages/topright-curve.svg) no-repeat;
    content: '';
    z-index: -1;
}

.login-page::after {
    width: 169px;
    height: 124px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: url(../Images/LoginImages/bottomleft-curve.svg) no-repeat;
    content: '';
    z-index: -1;
}

.login-page .col-lg-4 {
    height: 100vh;
    display: table;
}

.login-page .col-lg-4 .login-wrapper {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    position: relative;
    z-index: 999;
}

.login-page .col-lg-4 .login-wrapper button {
    width: 100%;
    padding: 0px 0px;
    margin: 0;
    border: none;
    outline: none;
    background: var(--PrimaryColor);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    min-height: 44px;
    border-radius: 25px;
    line-height: 44px;
}

.login-page .col-lg-4 .login-wrapper .form-group {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-bottom: 16px;
}

.login-page .col-lg-4 .login-wrapper .form-group i {
    color: #a3b8d3;
    position: absolute;
    font-size: 15px;
    left: 15px;
    top: 15px;
}

.login-page .col-lg-4 .login-wrapper .form-group input {
    width: 100%;
    padding: 0px 0px 0px 40px;
    margin: 0;
    min-height: 44px;
    border: 1px solid #a3b8d3;
    outline: none;
    border-radius: 25px;
    color: var(--textcolor);
    transition-duration: 0.3s;
}

.login-page .col-lg-4 .login-wrapper .form-group input:focus {
    border-color: var(--PrimaryColor);
}

.login-page .col-lg-4 .login-wrapper h6 {
    width: 100%;
    padding: 0;
    margin: 0px 0px 25px 0px;
    text-align: center;
    font-size: 15px;
    color: var(--textcolor);
    font-weight: normal;
}

.login-page .col-lg-4 .login-wrapper h3 {
    width: 100%;
    padding: 0;
    margin: 0px 0px 2px 0px;
    text-align: center;
    font-size: 32px;
    color: var(--headingColor);
    font-weight: 500;
}

.login-page .col-lg-4 .login-wrapper span {
    width: 100%;
    text-align: center;
    display: block;
    margin: 0px 0px 20px 0px;
}

.login-page .col-lg-4 .login-wrapper span img {
    width: 152px;
    height: 105px;
    display: inline-block;
}

.splash-page .col-lg-4 .login-wrapper {
    height: 100vh;
    width: 100%;
    display: table;
    position: relative;
    z-index: 900;
}

.splash-page .col-lg-4 .login-wrapper span {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.splash-page {
    position: relative;
}

.splash-page:before {
    width: 100%;
    height: 110px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
    content: '';
    background: url(../Images/LoginImages/header-top-repeat.jpg) repeat-x;
}

.splash-page:after {
    width: 100%;
    height: 227px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: -1;
    content: '';
    background: url(../Images/LoginImages/bottom-repeat.jpg) repeat-x;
}

.splash-page .col-lg-4 .login-wrapper h6 {
    position: absolute;
    left: 0px;
    bottom: 20px;
    height: 4px;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 0;
    margin: 0;
}

.splash-page .col-lg-4 .login-wrapper h6 span {
    height: 4px;
    background: #fff;
    border-radius: 4px;
    left: 0px;
    position: absolute;
    display: inline-block;
}

.splash-page .col-lg-4 .login-wrapper h6 font {
    position: absolute;
    left: 0px;
    top: -15px;
    color: #fff;
    font-size: 12px;
    margin-left: -22px;
}


@media screen and (max-width: 1024px) {}

@media screen and (max-width: 980px) {}

@media screen and (max-width: 740px) {}

@media screen and (max-width: 520px) {}
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}